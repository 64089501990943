import appConfig from "@/app.config";
import ISponsorshipRepository from "@/core/sponsorship/isponsorship.repository";
import Role from "@/models/auth/role.enum";
import HttpRepository from "../http.repository";

export default class SponsorshipRepository
  extends HttpRepository
  implements ISponsorshipRepository {

  private webApiUrls = {
    getMySponsorships: `${appConfig.webApiBaseUrl}/Sponsorship/GetMySponsorshipOffers`,
    createMyBabysitterEmailOffer: `${appConfig.webApiBaseUrl}/BabysitterSponsorship/CreateMySponsorshipEmailOffer`,
    createMyCustomerEmailOffer: `${appConfig.webApiBaseUrl}/CustomerSponsorship/CreateMySponsorshipEmailOffer`
  }

  public getMySponsorships(): Promise<any> {
    return this.httpGet(this.webApiUrls.getMySponsorships);
  }

  public sendInvite(role: Role, firstName: string, lastName: string, email: string): Promise<any> {
    const apiUrl: string =
      role === Role.Babysitter ? this.webApiUrls.createMyBabysitterEmailOffer
        : role === Role.Parent ? this.webApiUrls.createMyCustomerEmailOffer : undefined;

    return this.httpPost(apiUrl, {
      "SponsoredPartyFirstName": firstName,
      "SponsoredPartyLastName": lastName,
      "SponsoredPartyEmail": email
    });
  }
}

