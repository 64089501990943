







































































































import Vue, { PropType } from "vue";
import IAvailabilityModel from "@/models/babysitter/profile/ibabysitter-availability-model";
import * as _ from "lodash";
import { DayOfWeekEnum } from "@/models/babysitter/profile/day-of-week.enum";
import { getEnumValues } from "@/core/helpers/enum.helper";
import { FormValidators } from "@/app/helpers/forms/form.validators";
import EditableContainer from "@/app/components/common/EditableContainer.vue";

export default Vue.extend({
  name: "Availability",
  props: {
    availabilities: Array as PropType<IAvailabilityModel[]>,
  },
  components: {
    EditableContainer,
  },
  data() {
    return {
      availabilitiesForWriteSection: undefined,
      timeSlotRules: [FormValidators.required],
    };
  },
  computed: {
    groupedAvailabilitiesByDay(): any {
      return _.groupBy(this.availabilities.map((model) => { 
        return {
          ...model,
          order: model.day == 0 ? 7 : model.day
        }
      }).sort((a, b) => a.order - b.order), "order");
    },
    getWeekDaysEnumValues(): number[] {
      return getEnumValues(DayOfWeekEnum);
    },
    hasGroupedAvailabilitiesByDay(): boolean {
      return !_.isEmpty(this.groupedAvailabilitiesByDay);
    },
  },
  methods: {
    prepareData(): void {
      this.availabilitiesForWriteSection = this.getWeekDaysEnumValues.map(
        (day) => {
          return {
            day,
            order: (day == 0 ? 7 : day),
            dayIsSelected: !!this.groupedAvailabilitiesByDay[day],
            periods: this.groupedAvailabilitiesByDay[day]
              ? this.groupedAvailabilitiesByDay[day].map((_) => ({
                  startTime: _.startTime,
                  endTime: _.endTime,
                  id: _.id,
                }))
              : [],
          };
        }
      ).sort((a, b) => a.order - b.order);
    },
    save(): void {
      const updatedAvailabilities = this.availabilitiesForWriteSection
        .reduce((result, availability) => {
          if (availability.dayIsSelected && availability.periods.length > 0) {
            result.push(
              availability.periods.map((_) => ({
                day: availability.day,
                id: _.id,
                startTime: _.startTime,
                endTime: _.endTime,
              }))
            );
          }
          return result;
        }, [])
        .flatMap((_) => _);
      this.$emit("save", updatedAvailabilities);
    },
    removePeriod(day: number, periodId: number): void {
      const dayAvailabilities = this.availabilitiesForWriteSection.find(
        (_) => _.day == day
      );
      const period = dayAvailabilities.periods.find((_) => _.id == periodId);
      const index = dayAvailabilities.periods.indexOf(period);
      dayAvailabilities.periods.splice(index, 1);
    },
    addPeriod(day: number): void {
      const dayAvailabilities = this.availabilitiesForWriteSection.find(
        (_) => _.day == day
      );
      dayAvailabilities.periods.push({
        id: Date.now() & 0xffffffff,
      });
    },
  },
});
