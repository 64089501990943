import { ICustomerContractRepository } from "./icustomer-contract.repository";

export class CustomerContractService {
  private _repository: ICustomerContractRepository;

  public constructor(repository: ICustomerContractRepository) {
    this._repository = repository;
  }

  public getMyContracts(): Promise<any> {
    return this._repository.getMyContracts();
  }

  public getHouseworkContracts(): Promise<any> {
    return this._repository.getHouseworkContracts();
  }
}
