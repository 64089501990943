


































import appConfig from "@/app.config";
import { copyToClipboard } from "@/core/helpers/navigator.helpers";
import Vue from "vue";
export default Vue.extend({
  name: "SocialNetworks",
  props: {
    facebookCode: String,
    twitterCode: String,
    directLinkCode: String,
  },
  computed: {
    directLinkUrl(): string {
      return `${appConfig.wwwBaseUrl}/?codeparrainage=${this.directLinkCode}&source=parrainage&utm_source=yoopala.com&utm_campaign=parrainage`;
    },
    facebookShareUrl(): string {
      return `https://www.facebook.com/sharer/sharer.php?u=${appConfig.wwwBaseUrl}/?codeparrainage=${this.facebookCode}`;
    },
    twitterShareUrl(): string {
      return `http://twitter.com/intent/tweet?status=Besoin d'une babysitter à domicile ? Découvrez YOOPALA+${appConfig.wwwBaseUrl}/?codeparrainage=${this.twitterCode}`;
    },
  },
  methods: {
    copyLinkToClipboard() {
      copyToClipboard(this.directLinkUrl);
    },
  },
});
