









































































































import appFactories from "@/app.factories";
import { FormValidators } from "@/app/helpers/forms/form.validators";
import Vue from "vue";
import { mapState } from "vuex";
import { storeActions } from "../../store";
export default Vue.extend({
  name: "ResetPasswordPage",

  data() {
    return {
      resetKey: this.$route.query.resetKey || this.$route.query.resetkey,
      password: "",
      passwordConfirmation: "",
      validationRules: {
        password: [FormValidators.required],
        passwordConfirmation: [
          FormValidators.required,
          (this as any).checkNewPasswordConfirmation,
        ],
      },
    };
  },

  computed: {
    ...mapState({
      isLoading: (state: any) => state.global.isLoading,
    }),
    newPasswordIsRegexValid(): boolean {
      return /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,64})/.test(
        this.password
      );
    },
    newPasswordIsValidWithConfirmation(): boolean {
      return (
        this.password &&
        this.passwordConfirmation &&
        this.password === this.passwordConfirmation
      );
    },
    formIsValid(): boolean {
      return (
        !!this.password &&
        !!this.passwordConfirmation &&
        this.newPasswordIsRegexValid &&
        this.newPasswordIsValidWithConfirmation
      );
    },
  },

  methods: {
    checkNewPasswordConfirmation() {
      return (
        this.password === this.passwordConfirmation ||
        this.$i18n.t("UserSettingsPassword.checkNewPasswordConfirmation")
      );
    },

    async resetPassword() {
      if (this.isLoading) {
        return;
      }

      if (this.formIsValid) {
        await this.$store.dispatch(storeActions.global.isLoading, true);
        await appFactories.services.auth.resetPassword(
          this.password,
          this.resetKey as string
        );
        this.$store.dispatch(storeActions.global.showSuccess, {
          message: this.$i18n.t("ResetPassword.actionConfirmation"),
        });
        this.$store.dispatch(storeActions.user.clear);
        this.$router.replace("/login");
      }
    },
  },
});
