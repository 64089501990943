


































import Vue from "vue";
export default Vue.extend({
  name: "ErrorPage",

  data() {
    let errorMessages = ["Echec lors de la connexion.","Votre email ou votre mot de passe est erroné."];
    return {
      message: this.$route.query.message,
      redirectUrl: errorMessages.includes(this.$route.query.message as string) && this.$route.query.redirectUrl != null ? `/login?redirectUrl=${this.$route.query.redirectUrl}` : '/',
      buttonTxt: errorMessages.includes(this.$route.query.message as string) ? this.$i18n.t("LoginPage.login-action") : this.$i18n.t("HomePage")
    };
  },
});
