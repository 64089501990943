









































import appFactories from "@/app.factories";
import { FormValidators } from "@/app/helpers/forms/form.validators";
import { storeActions } from "@/app/store";
import Vue from "vue";
import { mapState } from "vuex";
export default Vue.extend({
  name: "Sponsor",
  data() {
    return {
      sponsoredPartyFirstName: "",
      sponsoredPartyLastName: "",
      sponsoredPartyEmail: "",
      validationRules: {
        email: [FormValidators.required, FormValidators.emailFormat],
      },
    };
  },
  computed: {
    ...mapState({
      role: (state: any) => state.user.role,
    }),
  },
  methods: {
    async sendInvite() {
      const form = this.$refs.form as any;
      if (form.validate()) {
        await this.$store.dispatch(storeActions.global.isLoading, true);
        try {
          await appFactories.services.sponsorship.sendInvite(
            this.role,
            this.sponsoredPartyFirstName,
            this.sponsoredPartyLastName,
            this.sponsoredPartyEmail
          );
          this.$store.dispatch(storeActions.global.showSuccess, {
            message: this.$i18n.t("Sponsor.action-confirmation"),
          });
        } finally {
          await this.$store.dispatch(storeActions.global.isLoading, false);
        }
      }
    },
  },
});
