import appConfig from "@/app.config";
import IAuthRepository from "@/core/auth/iauth.repository";
import IUserProfileInformationResponse from "@/models/user/user-profile-information";
import HttpRepository from "../http.repository";

export default class AuthRepository
  extends HttpRepository
  implements IAuthRepository {

  private webApiUrls = {
    login: `${appConfig.webApiBaseUrl}/Authentication/login`,
    logout: `${appConfig.webApiBaseUrl}/Authentication/logout`,
    getMyInforamtion: `${appConfig.webApiBaseUrl}/Authentication/GetMyInformation`,
    updatePassword: `${appConfig.webApiBaseUrl}/Authentication/updateMyPassword`,
    requestResetPassword: `${appConfig.webApiBaseUrl}/Authentication/requestResetMyPassword`,
    resetPassword: `${appConfig.webApiBaseUrl}/Authentication/resetMyPassword`,
    deleteAccount: `${appConfig.webApiBaseUrl}/Authentication/deleteMyAccount`,
  };

  public updatePassword(currentPassword: string, newPassword: string): Promise<void> {
    return this.httpPost(this.webApiUrls.updatePassword, { currentPassword, newPassword });
  }

  public getLoginUrl(): string {
    return this.webApiUrls.login;
  }

  public logout(): Promise<void> {
    return this.httpPost(this.webApiUrls.logout)
  }

  public deleteAccount(): Promise<void> {
    return this.httpPost(this.webApiUrls.deleteAccount);
  }

  public async getMyInformation(): Promise<IUserProfileInformationResponse> {
    return this.httpGet(this.webApiUrls.getMyInforamtion);
  }

  public requestResetPassword(email: string): Promise<void> {
    return this.httpPost(this.webApiUrls.requestResetPassword, { email });
  }

  public resetPassword(password: string, resetKey: string): Promise<void> {
    return this.httpPost(this.webApiUrls.resetPassword, { password, resetKey });
  }
}
