












































































































































import appConfig from "@/app.config";
import factories from "@/app.factories";
import BabySitterRenewalContractModel from "@/models/babysitter/babysitter-renewal-contract-model";
import NavigablePage from "@/app/components/common/NavigablePage.vue";
import Vue from "vue";
import { storeGetters }  from "@/app/store";
import { mapState } from "vuex";
import { EmployeeRenewalLinkedCustomersModel } from "@/models/babysitter/renewal/employee-renewal-linked-customer-model";
import { FamilyRenewalChoiceModel } from "@/models/babysitter/renewal/family-renewal-choice-model";
export default Vue.extend({
    components: { NavigablePage },
    name: "EmployeeRenewalPage",

    data() {
        return {
            model: new BabySitterRenewalContractModel(),
            isSent: false,
            currentYear: new Date().getFullYear(),
            nextYear: new Date().getFullYear() + 1,
            linkedFamilies: [] as EmployeeRenewalLinkedCustomersModel[],
            hasError: false,
            errorText: '' as string,
            isDisplayedInformationBanner: this.$store.getters[storeGetters.user.isDisplayedInformationBanner],
            formattedKnownAvailabilityDate: '' as string,
            mustRespondAllQuestionsTooltip: 'Vous devez répondre à toutes les questions avant d\'envoyer votre demande.' as string,
            maxCommentLength: 250,
            isButtonDisable: false
        };
    },
    methods: {
        formatDate(date: string) {
      if (date) {
        const isoDate =  new Date(date).toISOString();
        const parts = isoDate.split('T')[0].split('-');
        const formatted = `${parts[2]}-${parts[1]}-${parts[0]}`;
        this.formattedKnownAvailabilityDate = formatted;
      } else {
        this.formattedKnownAvailabilityDate = '';
      }
    },
    
        async sendForm() {
            this.isButtonDisable = true;
            try {
                if (this.checkForm()) {
                    this.model.bsID = this.babysitterId;
                    
                    await factories.services.babysitter.renewal.sendReminder(this.model);

                    if (this.model.knowAvailabilitiesBackToSchool)
                    {
                        window.location.href=`${appConfig.wwwBaseUrl}/qualification/intervenant/disponibilites`;
                    }
                    
                    this.isSent = true;
                } else {
                    this.hasError = true;
                    this.errorText = "Le formulaire contient une erreur ! Merci de vérifier les champs renseignés.";
                }
            } catch (e) {
                this.hasError = true;
                this.errorText = "Une erreur est survenue ! Merci de re-éssayer ulterieurement.";
            }
            this.isButtonDisable = false;
        },

        checkForm: function() {
            let isValid = false;

            if (this.model.needRenewContract === false && this.model.notRenewingReason != null) {
                isValid = true;
            }

            if (this.model.needRenewContract === true) {
                if (this.model.familiesRenewalChoices.length === 0)
                {
                    isValid = true;
                }
                else if (this.model.familiesRenewalChoices.length > 0) {
                    this.model.familiesRenewalChoices.forEach(_ => {
                        isValid = _.wantToRenew != null;
                    })
                }

                if(isValid){
                    isValid = (this.model.knowAvailabilitiesBackToSchool != null
                        && (this.model.knowAvailabilitiesBackToSchool === false ? this.model.knownAvailabilityDate != null : true));
                }
            }

            return isValid;
        },

        disablePastDates(val) {
            return val >= new Date().toISOString().substr(0, 10)
        },

        resetNotRenewingReason: function (val: boolean) {
            if (val === true) {
                this.model.notRenewingReason = null;
            }

            if (val === false) {
                this.model.familiesRenewalChoices.forEach(_ => {
                    _.comment = null;
                    _.wantToRenew = null;
                });

                this.model.knowAvailabilitiesBackToSchool = null;
                this.model.knownAvailabilityDate = null;
                this.formattedKnownAvailabilityDate = '';
            }
        },

        resetAvaibilityDate: function () {
            if (this.model.knowAvailabilitiesBackToSchool) {
                this.model.knownAvailabilityDate = null;
                this.formattedKnownAvailabilityDate = '';
            }
        },
        resetCommentField: function (family: FamilyRenewalChoiceModel) {
            if (family.wantToRenew) {
                family.comment = null;
            }
        }
    },
    computed: {
        ...mapState({
            babysitterId: (state: any) => state.user.id,
        })
    },
    mounted: async function () {
        this.linkedFamilies = (await factories.services.babysitter.renewal.getLinkedFamilies()).result;

        this.linkedFamilies.forEach(_ => this.model.familiesRenewalChoices.push(new FamilyRenewalChoiceModel(_.customerID, _.customerDisplayName, _.salesRepID, _.salesRepDisplayName)));
    }
});
