export class FamilyRenewalChoiceModel {
    public customerID: number;
    public customerDisplayName: string;
    public wantToRenew?: boolean;
    public comment: string;
    public salesRepID: number;
    public salesRepDisplayName: string;

    public constructor(customerID: number, customerDisplayName: string, salesRepID: number, salesRepDisplayName: string) {
        this.customerID = customerID;
        this.customerDisplayName = customerDisplayName;
        this.salesRepID = salesRepID;
        this.salesRepDisplayName = salesRepDisplayName;
        this.wantToRenew = null;
        this.comment = null;
    }
}