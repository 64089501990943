












































import Vue from "vue";
import Role from "@/models/auth/role.enum";
import { storeActions, storeGetters } from "@/app/store";
import { mapState } from "vuex";
import factories from "@/app.factories";
import HomePageContainer from "@/app/home/components/HomePageContainer.vue";

export default Vue.extend({
  name: "HomePage",

  components: {
    HomePageContainer,
  },

  data() {
    return {
      links: undefined,
      isDisplayedInformationBanner: this.$store.getters[storeGetters.user.isDisplayedInformationBanner],
      isParent: this.$store.getters[storeGetters.user.role] === Role.Parent
    };
  },

  async mounted() {
    await this.loadSections();
  },

  methods: {
    async loadSections() {
      await this.$store.dispatch(storeActions.global.isLoading, true);
      try {
        this.links = factories.services.home.getLinks(
          this.userRole,
          this.isEmployee
        );
      } finally {
        await this.$store.dispatch(storeActions.global.isLoading, false);
      }
    },
  },

  computed: {
    ...mapState({
      isEmployee: (state: any) => state.user.hasContracts,
      userRole: (state: any) => state.user.role,
    }),
  },
});
