import {CustomerEmployeeRenewalCompleteViewModel}  from "@/models/customer/renewal/customer-employee-renewal-complete-list-view-model";

export default class CompleteBackToSchoolInformationCommand {
    public constructor(
    public needRenewContract : boolean|null,
    public needContinueWithBS : boolean|null,
    public needBSForSummer: boolean|null,
    public commentNeedNewBS: string,
    public needComment : string,
    public parentID: number,
    public linkedEmployees: CustomerEmployeeRenewalCompleteViewModel[]
    )
    {

    }
}