import appConfig from "@/app.config";
import { IEmployeeContractRepository } from "@/core/employees/contracts/iemployee-contract.repository";
import HttpRepository from "@/infrastructure/http.repository";

export class EmployeeContractRepository
  extends HttpRepository
  implements IEmployeeContractRepository {

  private webApiUrls = {
    getMyContracts: `${appConfig.webApiBaseUrl}/EmployeeContract/GetMyContracts`,
  };

  public getMyContracts(): Promise<any> {
    return this.httpGet(this.webApiUrls.getMyContracts);
  }
}
