














































































import Vue from "vue";
import EditableContainer from "@/app/components/common/EditableContainer.vue";
export default Vue.extend({
  name: "Transport",
  props: {
    drivingLicense: Boolean,
    conveyChildren: Boolean,
  },
  components: {
    EditableContainer,
  },
  data() {
    return {
      hasDrivingLicence: this.drivingLicense,
      canConveyChildren: this.conveyChildren,
    };
  },
  methods: {
    revert() {
      this.hasDrivingLicence = this.drivingLicense;
      this.canConveyChildren = this.conveyChildren;
    },
    saveTransport() {
      this.$emit("save", {
        hasDrivingLicence: this.hasDrivingLicence,
        canConveyChildren: this.canConveyChildren,
      });
    },
  },
});
