import factories from "@/app.factories";
import { Module } from "vuex";
import Role from "@/models/auth/role.enum";
import { fileToBase64Url } from "@/core/helpers/file.helpers";
import appConfig from "@/app.config";
import { UserAvatarStateEnum } from "@/models/user/user-avatar-state.enum";
import IUserProfileInformationResponse from "@/models/user/user-profile-information";

interface IUserStoreState {
  id: number;
  displayName?: string;
  username: string;
  email?: string;
  role: Role;
  hasContracts?: boolean;
  avatarUrl?: string;
  avatarState?: UserAvatarStateEnum;
  city?: string;
  latitude?: number;
  longitude?: number;
  isDisplayedInformationBanner: boolean;
}

const initialState: () => IUserStoreState = () => ({
  id: undefined,
  displayName: undefined,
  username: undefined,
  email: undefined,
  role: undefined,
  hasContracts: undefined,
  avatarUrl: undefined,
  avatarState: undefined,
  isDisplayedInformationBanner: false
});

export const userStoreActions = {
  init: "user/init",
  updateAvatar: "user/updateAvatar",
  deleteAvatar: "user/deleteAvatar",
  logout: "user/logout",
  clear: "user/clear",
};

export const userStoreGetters = {
  avatarUrl: "user/avatarUrl",
  defaultAvatar: "user/defaultAvatar",
  hasAvatar: "user/hasAvatar",
  isAuthenticated: "user/isAuthenticated",
  frontSearchUrl: "user/frontSearchUrl",
  role: "user/role",
  isDisplayedInformationBanner: "user/isDisplayedInformationBanner"
};

export default {
  namespaced: true,
  state: initialState,

  mutations: {
    clear(state) {
      Object.assign(state, initialState());
    },
    initInformations(state, payload: IUserProfileInformationResponse) {
      state.email = payload.eMail;
      state.displayName = payload.displayName;
      state.avatarUrl = payload.avatarUrl;
      state.avatarState = payload.avatarState;
      state.hasContracts = payload.hasContracts;
      state.username = payload.userName;
      state.id = payload.userId;
      state.role = payload.role;
      state.city = payload.city;
      state.longitude = payload.longitude;
      state.latitude = payload.latitude;
      state.isDisplayedInformationBanner = payload.isDisplayedInformationBanner;
    },
    updateAvatarUrl(state, payload: string) {
      state.avatarUrl = payload;
      state.avatarState = payload ? UserAvatarStateEnum.PendingValidation : undefined;
    }
  },

  getters: {
    avatarUrl(state) {
      return state.avatarUrl || require("@/assets/images/dashboard/user.svg");
    },
    defaultAvatar() {
      return require("@/assets/images/dashboard/user.svg");
    },
    hasAvatar(state) {
      return !!state.avatarUrl;
    },
    isAuthenticated(state) {
      return !!state.id;
    },
    frontSearchUrl(state) {
      const searchAction = state.role === Role.Babysitter ? 'emploi-job' : state.role === Role.Parent ? 'annonce' : '';
      let searchUrl = `${appConfig.wwwBaseUrl}/${searchAction}/recherche?Place=${state.city}`;
      if(state.latitude == null || state.longitude == null ) {
        searchUrl += `&City=${state.city}`
      }
      else if (state.latitude && state.longitude) {
        searchUrl += `&lat=${state.latitude}&lng=${state.longitude}`;
      }
      searchUrl += `&Area=&Filters.OfferTypes.FromBackOffice=True`;
      
      return searchUrl;
    },
    role(state) {
      return state.role;
    },
    isDisplayedInformationBanner(state) {
      return state.isDisplayedInformationBanner;
    }
  },

  actions: {
    async init({ commit }) {
      const userInformation = await factories.services.auth.getMyInformation();
      factories.services.localStorage.setIsAuth(true);
      commit("initInformations", userInformation);
    },

    async updateAvatar({ commit }, payload: File) {
      await factories.services.babysitter.profile.updateAvatar(payload);
      commit('updateAvatarUrl', await fileToBase64Url(payload));
    },

    async deleteAvatar({ commit }) {
      await factories.services.babysitter.profile.deleteAvatar();
      commit('updateAvatarUrl', undefined);
    },

    logout({ dispatch }) {
      factories.services.auth.logout();
      dispatch('clear');
    },

    clear({ commit }) {
      factories.services.localStorage.setIsAuth(false);
      commit("clear");
    },
  }

} as Module<IUserStoreState, unknown>
