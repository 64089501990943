import IHomeLinksCatalog from "@/core/common/ihome-links-catalog";
import IHomeLinkModel from "@/models/common/ihome-link-model";

export default class CustomerHomeLinksCatalog implements IHomeLinksCatalog {

  private catalog: Array<IHomeLinkModel> = [
    {
      title: "Catalogs.CustomerAgencySections.contract.title",
      description: "Catalogs.CustomerAgencySections.contract.description",
      imageSource: require("@/assets/images/dashboard/contract.svg"),
      navigateTo: "/client/contrats",
      needContract: true
    },
    {
      title: "Catalogs.CustomerAgencySections.prestation.title",
      description: "Catalogs.CustomerAgencySections.prestation.description",
      imageSource: require("@/assets/images/dashboard/business-time.svg"),
      navigateTo: "/client/interventions",
      needContract: true,
    },
    {
      title: "Catalogs.CustomerAgencySections.invoices.title",
      description: "Catalogs.CustomerAgencySections.invoices.description",
      imageSource: require("@/assets/images/dashboard/invoice.svg"),
      navigateTo: "/client/factures",
      needContract: true
    },
    {
      title: "Catalogs.CustomerAgencySections.payments.title",
      description: "Catalogs.CustomerAgencySections.payments.description",
      imageSource: require("@/assets/images/dashboard/card.svg"),
      navigateTo: "/client/reglements",
      needContract: true
    },
    {
      title: "Catalogs.CustomerAgencySections.fiscal-certificates.title",
      description: "Catalogs.CustomerAgencySections.fiscal-certificates.description",
      imageSource: require("@/assets/images/dashboard/tax.svg"),
      navigateTo: "/client/attestations",
      needContract: true
    },
    {
      title: "Catalogs.CustomerAgencySections.contacts.title",
      description: "Catalogs.CustomerAgencySections.contacts.description",
      imageSource: require("@/assets/images/dashboard/contacts.svg"),
      navigateTo: "/client/contacts",
      needContract: false
    },
    {
      title: "Catalogs.CustomerAgencySections.profiles.title",
      description: "Catalogs.CustomerAgencySections.profiles.description",
      imageSource: require("@/assets/images/dashboard/profiles.svg"),
      navigateTo: "/client/profiles",
      needContract: false,
    },
    {
      title: "Catalogs.CustomerAgencySections.sponsorship.title",
      description: "Catalogs.CustomerAgencySections.sponsorship.description",
      imageSource: require("@/assets/images/dashboard/hands.svg"),
      navigateTo: "/client/parrainage",
      needContract: false,
      displayInHeader: true,
    },
    // {
    //   title: "Catalogs.CustomerAgencySections.estimations.title",
    //   description: "Catalogs.CustomerAgencySections.estimations.description",
    //   imageSource: require("@/assets/images/dashboard/quote.svg"),
    //   navigateTo: "",
    //   needContract: false
    // },
  ];

  public getLinks(hasContract: boolean): IHomeLinkModel[] {
    if (hasContract) {
      return this.catalog;
    } else {
      return this.catalog.filter(_ => _.needContract === false);
    }

  }
}
