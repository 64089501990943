export enum HouseworkContractStateEnum {
  Draft = 0,
  ReadyToSend = 1,
  WaitingSignature = 2,
  Signed = 3,
  InProgress = 4,
  Finished = 5,
  Canceled = 6,
  Declined = 7,
  Expired = 8,
  Error = 99
}
