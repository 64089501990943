

















import Vue from "vue";

export default Vue.extend({
  name: "ContactsCardInfo",
  props: {
    title: String,
    desc: String,
  },
  components: {
  },

  methods: {
  }

})
