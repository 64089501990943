import { NavigationGuardNext, Route } from "vue-router";
import store from "../../store";

async function authGuard(
  to: Route,
  from: Route,
  next: NavigationGuardNext<Vue>
): Promise<void> {
  const state: any = store.state;

  if (to.matched.some((record) => record.meta.auth)) {
    if (!state.user.id && from.path !== '/login') {
      next({
        path: "/login",
        query: { redirectUrl: new URL(to.fullPath, window.location.origin).href },
        replace: true,
      });
    } else {
      next();
    }
  } else {
    next();
  }
}

export default authGuard;
