export enum CriteriaEnum {
  DrivingLicence = 7,
  Car = 8,
  PetPresence = 1230,
  HomeworkHelp = 5,

  Anglais = 1,
  Espagnol = 2,
  Italien = 3,
  Allemand = 4,
  CAPPetiteEnfance = 6,
  DiplomeAnimationBAFA = 9,
  DiplomedetatMoniteurEducateur = 11,
  DiplomedetatAideSoignant = 13,
  Babysitting = 14,
  Entretiendudomicile = 15,
  Profil1an = 16,
  Profil3ans = 17,
  ProfilPlus3ans = 18,
  ProfilPlus6ans = 19,
  Nouveaune = 20,
  Plusde1an = 21,
  Plusde3ans = 22,
  Plusde6ans = 23,
  Experienceavec1an = 24,
  Experienceavec3ans = 25,
  ExperienceavecPlus3ans = 26,
  ExperienceavecPlus6ans = 27,
  Extraverti = 28,
  Discret = 29,
  Honnˆte = 30,
  Attentionne = 31,
  Creatif = 32,
  Joueur = 33,
  Meticuleux = 34,
  Naturel = 35,
  Optimiste = 36,
  Ponctuel = 37,
  Patient = 38,
  Tolerant = 39,
  Imaginatif = 40,
  Sociable = 41,
  Drole = 42,
  Cultivee = 43,
  Sport = 44,
  Voyages = 45,
  Pratiqueuninstrumentdemusique = 46,
  Lecture = 47,
  Cinema = 48,
  CAPBEP = 49,
  BacPlus1 = 50,
  BacPlus2 = 51,
  BacPlus3 = 52,
  BacPlus4 = 53,
  BacPlus5 = 54,
  Agriculture = 55,
  Architecture = 56,
  Art = 57,
  Artisanat = 58,
  Audiovisuel = 59,
  Banquesetassurances = 60,
  Batiment = 61,
  Coiffure = 62,
  Commerce = 63,
  Communication = 64,
  Comptabilite = 65,
  Droit = 66,
  Economie = 67,
  Environnement = 68,
  Immobilier = 69,
  Langue = 70,
  Marketing = 71,
  Mathematiques = 72,
  Publicite = 73,
  Qualite = 74,
  RessourcesHumaines = 75,
  Secretariat = 76,
  Sport2 = 77,
  Tourisme = 78,
  Vente = 79,
  Autre = 80,
  PSC1 = 81,
  DiplomeAnimationBAFD = 82,
  BEPCarriŠresSanitairesetSociales = 83,
  BACSMSSciencesMedicoSocialST2S = 84,
  DUTCarriŠresSociales = 85,
  DiplomedetatInfirmier = 86,
  Facdemedecine2Šmeannee = 87,
  DiplomedetatEducateurSpecialise = 88,
  DiplomedetatEducateurdeJeunesEnfants = 89,
  CQPAssistantdevie = 91,
  Diplomeliesauxservicealapersonne = 92,
  BEPAgricoleServiceauxpersonnes = 93,
  DiplomedetatAssistantfamilial = 94,
  SST = 95,
  DiplomedetatPuericultrice = 96,
  ATSEM = 97,
  DiplomedetatAuxiliairedePuericulture = 98,
  Deuxroues = 99,
  Velo = 100,
  Transportencommun = 101,
  Demandedecertification = 102,
  BsCertifieYoopala = 103,
  Yoopalaestlemployeur = 104,
  Smartphone = 105,
  Francais = 106,
  Donnerlebain = 107,
  Donnerlerepas = 108,
  Fairelerepas = 109,
  Activitesetjeux = 110,
  Portugais = 111,
  Russe = 112,
  Arabe = 113,
  Chinois = 114,
  Japonais = 115,
  Autreslangues = 116,
  Pasdenumerodesecuritesociale = 117,
  Recherchebilingue = 118,
  Planningvariable = 119,
  Aideauxdevoirs = 120,
  Pieton = 121,
  Vehiculepourtransporterlesenfants = 122,
  Recherchetempsplein = 123,
  Disponibleuniquementdurantvacancesscolaires = 124,
  Recherchegardeponctuelle = 125,
  AdejatravailleavecYoopala = 126,
  Testdepersonnalite = 127,
  FormationElearning = 128,
  Lever = 129,
  ToiletteHabillage = 130,
  Petitdejeuner = 131,
  AccompagnementcrŠcheounounou = 132,
  Accompagnementalecole = 133,
  AccompagnementactivitesouRDVmedicaux = 134,
  PrisedesrepasDejeuner = 135,
  Preparationdesbiberons = 136,
  Sieste = 137,
  PrisedesrepasGouter = 138,
  SortiedecrŠcheounounou = 139,
  Sortiedecole = 140,
  SoinsetHygiŠneSurveillanceduBain = 141,
  PrisedesrepasDŒner = 142,
  Coucher = 143,
  Jeuxloisirslecture = 144,
  Promenade = 145,
  Surveillancedesdevoirs = 146,
  EtapeinscriptionBS = 147,
  Remplacementurgent = 148,
  JobBoards = 149,
  Salarieaoptimiser = 150,
  BEPASSP = 151,
  CertifTitreProfessionnelADVF = 153,
  CQPGardedenfantsadomicile = 156,
  MentionComplementaireAideadomicile = 157,
  DiplomedetatAidemedicopsychologique = 158,
  DiplomedetatAssistantsocial = 160,
  DiplomedetatAuxiliairedeviesociale = 161,
  DiplomeAnimationBAPAAT = 162,
  BACPROASSPAccomSoinServicealaPersonne = 163,
  BACPROSAPATserviceauxpersonnesetterrit = 164,
  CAPAssisTechnenMilieuFamilialetCollectif = 165,
  CertifTitreProfessionnelEmployeFamilial = 166,
  CertifTitreProfessionnelAuxParamedical = 167,
  DiplomedetatTechndintervsocialfamilial = 168,
  Oui = 169,
  Non = 170,
  Sortiedecole2 = 171,
  Matinees = 172,
  Nuit = 173,
  Weekend = 174,
  Soireesponctuelles = 175,
  Avantlecole = 176,
  Gardedeplusieursenfants = 177,
  Pratiquedunsport = 178,
  Theƒtre = 179,
  Membreduneassociationoubenevolat = 180,
  Loisirscreatifs = 181,
  Photographie = 182,
  TechnologiHighTech = 183,
  SouhaitNbenfantagarder = 184,
  Salarieaoptimiser2 = 185,
  Acceptationnewsletter = 186,
  Acceptationnewsletterpartenaires = 187,
  Midi = 221,
  Apresmidi = 223,
  Mercredi = 224,
  MissionNouveauclient = 1221,
  MissionRenouvellement = 1222,
  MissionRetourclient = 1223,
  MissionAvenant = 1224,
  FinDeSuivi = 1225,
  Handicapenfant = 1226,
  Alarentreeapartirdeseptembre = 1227,
  Cetetejuilletetaout = 1228,
  DeuxiŠmeprofil = 1229,
  Experienceenbabysitting = 1232,
  Peutfournirdesreferences = 1233,
  Okcontratpro = 1234,
  Blocagetropdecontacts = 1235,
  FinDeTraitement = 1236,
  AnnulerFinDeTraitement = 1237,
  Mission1havant17h30 = 1238,
  Agrementdassistantematernelle = 1241,
  FormationPSC1ouSTT = 1242,
  Diplomedanslesecteurdelenfance = 1247,
  Diplomedanslesecteurdumedicosocial = 1248,
  Diplomedanslesecteurdelanimation = 1249,
  Autre2 = 1250,
  Neerlandais = 1251,
  Changerunenfant = 1252,
  Donnerlebiberon = 1253,
  Disponiblepourlesgardesdurgence = 1254,
  Disponiblepourlemenage = 1256,
  BEPC = 1258,
  Bac = 1259,
  BacPlus8Doctorat = 1260,
  OptionRepassage = 1261,
  SansDiplome = 1262,
  TitreProfessionnelAssistantDeVieAuxFamilles = 1263,
  BACPROServicesMilieuRural = 1266,
  DiplomeEtatAideMedicoPsychologique = 1268,
  DiplomeEtatAssistanteFamiliale = 1269,
  BEPAServicesSpecialiteServicesAuxPersonnes = 1276,
  BTSEconomieSocialeEtFamiliale = 1278,
  MASTERSciencesEducation = 1286,
  MASTERsciencesHumainesEtSociales = 1287,
  DiplomeEtatTechnicienInterventionSocialeEtFamiliale = 1292,
  BTSServicesEtPrestationEnSecteurSanitaireEtSocialSP3S = 1294,
  DiplomeDeConseillerEnEconomieSocialeEtFamiliale = 1295
}

export enum CriteriaEducationLevelEnum {
  BEPC = CriteriaEnum.BEPC,
  CAPBEP = CriteriaEnum.CAPBEP,
  Bac = CriteriaEnum.Bac,
  BacPlus1 = CriteriaEnum.BacPlus1,
  BacPlus2 = CriteriaEnum.BacPlus2,
  BacPlus3 = CriteriaEnum.BacPlus3,
  BacPlus4 = CriteriaEnum.BacPlus4,
  BacPlus5 = CriteriaEnum.BacPlus5,
  BacPlus8Doctorat = CriteriaEnum.BacPlus8Doctorat,
  SansDiplome = CriteriaEnum.SansDiplome
}

export enum CriteriaEducationThemeEnum {
  Agriculture = CriteriaEnum.Agriculture,
  Architecture = CriteriaEnum.Architecture,
  Art = CriteriaEnum.Art,
  Artisanat = CriteriaEnum.Artisanat,
  Audiovisuel = CriteriaEnum.Audiovisuel,
  Banquesetassurances = CriteriaEnum.Banquesetassurances,
  Batiment = CriteriaEnum.Batiment,
  Coiffure = CriteriaEnum.Coiffure,
  Commerce = CriteriaEnum.Commerce,
  Communication = CriteriaEnum.Communication,
  Comptabilite = CriteriaEnum.Comptabilite,
  Droit = CriteriaEnum.Droit,
  Economie = CriteriaEnum.Economie,
  Environnement = CriteriaEnum.Environnement,
  Immobilier = CriteriaEnum.Immobilier,
  Langue = CriteriaEnum.Langue,
  Marketing = CriteriaEnum.Marketing,
  Mathematiques = CriteriaEnum.Mathematiques,
  Publicite = CriteriaEnum.Publicite,
  Qualite = CriteriaEnum.Qualite,
  RessourcesHumaines = CriteriaEnum.RessourcesHumaines,
  Secretariat = CriteriaEnum.Secretariat,
  Sport = CriteriaEnum.Sport2,
  Tourisme = CriteriaEnum.Tourisme,
  Vente = CriteriaEnum.Vente,
  Autre = CriteriaEnum.Autre
}

export enum CriteriaDiplomasEnum {
  Agrementdassistantematernelle = CriteriaEnum.Agrementdassistantematernelle,
  FormationPSC1ouSTT = CriteriaEnum.FormationPSC1ouSTT,
  Diplomedanslesecteurdelenfance = CriteriaEnum.Diplomedanslesecteurdelenfance,
  Diplomedanslesecteurdumedicosocial = CriteriaEnum.Diplomedanslesecteurdumedicosocial,
  Diplomedanslesecteurdelanimation = CriteriaEnum.Diplomedanslesecteurdelanimation,
  Autre = CriteriaEnum.Autre2
}

export enum CriteriaSpokenLanguageEnum {
  French = CriteriaEnum.Francais,
  English = CriteriaEnum.Anglais,
  German = CriteriaEnum.Allemand,
  Spanish = CriteriaEnum.Espagnol,
  Italian = CriteriaEnum.Italien,
  Russian = CriteriaEnum.Russe,
  Arabian = CriteriaEnum.Arabe,
  Dutch = CriteriaEnum.Neerlandais,
  Portuguese = CriteriaEnum.Portugais,
  Chinese = CriteriaEnum.Chinois,
  Other = CriteriaEnum.Autreslangues
}

export enum CriteriaChildCareSkillsEnum {
  Houseworks = CriteriaEnum.Aideauxdevoirs,
  Bath = CriteriaEnum.Donnerlebain,
  ChangeChildren = CriteriaEnum.Changerunenfant,
  Cook = CriteriaEnum.Fairelerepas,
  GiveTheMeal = CriteriaEnum.Donnerlerepas,
  FeedBaby = CriteriaEnum.Donnerlebiberon,
  ActivitiesAndGames = CriteriaEnum.Activitesetjeux
}
