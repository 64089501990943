import ICustomerRenewalRepository from "./icustomer-renewal.repository";
import CompleteBackToSchoolInformationCommand  from "@/models/customer/renewal/customer-renewal-model";
import {CustomerEmployeeRenewalCompleteViewModel}  from "@/models/customer/renewal/customer-employee-renewal-complete-list-view-model";

export default class CustomerRenewalService {
  private _customerRepository: ICustomerRenewalRepository;

  public constructor(customerRepository: ICustomerRenewalRepository) {
    this._customerRepository = customerRepository;
  }

  public async sendBackToSchoolForm(model: CompleteBackToSchoolInformationCommand): Promise<void>{
   await  this._customerRepository.sendBackToSchoolForm(model);
  }

  public async getCustomerRenewalCompleteLinkedEmployees(): Promise<CustomerEmployeeRenewalCompleteViewModel[]>{
    return await  this._customerRepository.getCustomerRenewalCompleteLinkedEmployees();
  }
}