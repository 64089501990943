import * as _ from "lodash";

export function getEnumValues(enumType) {
  return Object.keys(enumType)
    .filter(item => isNaN(Number(item)))
    .map(item => enumType[item]);
}

export function getEnumValuesOrderByLabel(enumType, $t, $tBaseKey): any[] {
  return _.sortBy(
    getEnumValues(enumType).map((v) => ({
      value: v,
      label: $t(`${$tBaseKey}.${v}`),
    })),
    ["label"]
  ).map((_) => _.value);
}

export function getEnumFlags(enumType, value) {
  const isFlag = (arg) => {
    const nArg = Number(arg);
    const isNumber = !Number.isNaN(nArg);
    return isNumber && isPowerOfTwo(nArg);
  };

  const enumFlags = [];

  Object.keys(enumType).forEach(key => {
    const nKey = Number(key);
    if (isFlag(nKey) && (value & nKey)) {
      enumFlags.push(nKey);
    }
  });

  return enumFlags;
}

const isPowerOfTwo = (x: number): boolean => {
  return x != 0 && (x & (x - 1)) == 0;
};