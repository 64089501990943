










import { storeActions } from "@/app/store";
import Vue from "vue";

export default Vue.extend({
  name: "HomePageContainer",
  props: {
    title: String,
    description: String,
    imageSource: String,
    navigateTo: String,
  },
  methods: {
    async next() {
      if (this.navigateTo) {
        await this.$store.dispatch(storeActions.global.isLoading, true);
        await this.$router.push(this.navigateTo);
        await this.$store.dispatch(storeActions.global.isLoading, false);
      }
    },
  },
});
