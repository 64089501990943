import ICustomerRenewalRepository from "@/core/customer/renewal/icustomer-renewal.repository";
import appConfig from "@/app.config";
import HttpRepository from "@/infrastructure/http.repository";
import CompleteBackToSchoolInformationCommand  from "@/models/customer/renewal/customer-renewal-model";
import {CustomerEmployeeRenewalCompleteViewModel}  from "@/models/customer/renewal/customer-employee-renewal-complete-list-view-model";

export default class CustomerRenewalRepository
  extends HttpRepository
  implements ICustomerRenewalRepository {

  public sendBackToSchoolForm(model: CompleteBackToSchoolInformationCommand): Promise<void>{
    return this.httpPost(`${appConfig.webApiBaseUrl}/customerrenewal/sendbacktoschoolreminder`, model);
  }

  public getCustomerRenewalCompleteLinkedEmployees(): Promise<CustomerEmployeeRenewalCompleteViewModel[]>{
    return this.httpGet(`${appConfig.webApiBaseUrl}/customerrenewal/GetCustomerRenewalCompleteLinkedEmployees`);
  }
}
