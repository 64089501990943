export enum BabySitterContractStateEnum {
  Draft = 0,
  ReadyToSend = 1,
  PendingSignature = 2,
  Signed = 3,
  Error = 99,
  Finished = 7,
  Canceled = 8,
  Expired = 5,
  Refused = 6,
  InProgress = 9
}
