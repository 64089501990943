































































































import Vue from "vue";
export default Vue.extend({
  name: "AppMobileMenu",

  props: ["open", "yoopalaLinks", "userLinks", "wwwBaseUrl", "settingsPageUrl"],
});
