























































































































































































































































































































import Vue from "vue";
import Role from "@/models/auth/role.enum";
import { storeGetters }  from "@/app/store";
import * as _ from "lodash";
import NavigablePage from "@/app/components/common/NavigablePage.vue";
import appFactories from "@/app.factories";
import { ParentContractStateEnum } from "@/models/customer/ParentContractStateEnum.enum";
import { HouseworkContractStateEnum } from "@/models/customer/HouseworkContractStateEnum.enum";
import { HouseworkContractTypologieEnum } from "@/models/customer/HouseworkContractTypologieEnum.enum";
export default Vue.extend({
  components: { NavigablePage },
  name: "CustomerContractsPage",

  data() {
    return {
      isLoading: true,
      contracts: [],
      hwContracts: [],
      showAllContracts: false,
      isDisplayedInformationBanner: this.$store.getters[storeGetters.user.isDisplayedInformationBanner],
      isParent: this.$store.getters[storeGetters.user.role] == Role.Parent
    };
  },

  async mounted() {
    await this.loadContracts();
    await this.loadHousekeeperContracts();
  },

  methods: {
    async loadContracts() {
      this.isLoading = true;
      this.contracts =
        await appFactories.services.customer.contract.getMyContracts();
      this.isLoading = false;
    },
    getContractStateColor(contractState) {
      let color = "";

      switch (contractState) {
        case ParentContractStateEnum.InProgress:
          color = "green";
          break;
        case ParentContractStateEnum.WaitingForParentSignature:
          color = "blue";
          break;
        case ParentContractStateEnum.SignedByParent:
          color = "teal";
          break;
        case ParentContractStateEnum.Finished:
          color = "primary";
          break;
        case ParentContractStateEnum.Refused:
          color = "default";
          break;
        case ParentContractStateEnum.Canceled:
          color = "red";
          break;
        default:
          break;
      }

      return color;
    },
    async loadHousekeeperContracts() {
      if (this.hwContracts.length <= 0) {
        this.hwContracts =
          await appFactories.services.customer.contract.getHouseworkContracts();
      }
    },
    houseworkContractStateDisplay(hwContractState) {
      return HouseworkContractStateEnum[hwContractState];
    },
    getHwContractStateColor(hwContractState) {
      let color = "";

      switch (hwContractState) {
        case HouseworkContractStateEnum.InProgress:
          color = "green";
          break;
        case HouseworkContractStateEnum.WaitingSignature:
          color = "blue";
          break;
        case HouseworkContractStateEnum.Signed:
          color = "teal";
          break;
        case HouseworkContractStateEnum.Finished:
          color = "primary";
          break;
        case HouseworkContractStateEnum.Expired:
          color = "default";
          break;
        case HouseworkContractStateEnum.Canceled:
          color = "red";
          break;
        default:
          break;
      }

      return color;
    },
    getColorBasedOnHouseworkContractType(hwContractType) {
      let color = "";

      switch (hwContractType) {
        case HouseworkContractTypologieEnum.Cleaning:
          color = "teal";
          break;
        case HouseworkContractTypologieEnum.CleaningAndIroning:
          color = "primary";
          break;
        default:
          break;
      }

      return color;
    },
    getHouseworkTypologieName(hwTypologie) {
      return HouseworkContractTypologieEnum[hwTypologie];
    },
  },

  computed: {
    displayedContracts(): any[] {
      if (!this.showAllContracts) {
        const contractBaseReferencesToDisplay: string[] = this.contracts
          .filter(
            (c) =>
              [
                ParentContractStateEnum.WaitingForParentSignature,
                ParentContractStateEnum.SignedByParent,
                ParentContractStateEnum.InProgress,
              ].indexOf(c.state) > -1
          )
          .map((c) => c.baseReference);

        return _.chain(this.contracts)
          .filter(
            (c) => contractBaseReferencesToDisplay.indexOf(c.baseReference) > -1
          )
          .orderBy(["reference"], ["desc"])
          .value();
      }

      return _.chain(this.contracts).orderBy(["reference"], ["desc"]).value();
    },
    displayedHouseworkContracts(): any[] {
      if (this.hwContracts != null) {
        if (!this.showAllContracts) {
          const hwcontractBaseReferencesToDisplay: string[] = this.hwContracts
            .filter(
              (c) =>
                [
                  HouseworkContractStateEnum.WaitingSignature,
                  HouseworkContractStateEnum.Signed,
                  HouseworkContractStateEnum.InProgress,
                ].indexOf(c.state) > -1
            )
            .map((c) => c.reference);

          return _.chain(this.hwContracts)
            .filter(
              (c) =>
                hwcontractBaseReferencesToDisplay.indexOf(c.reference) > -1
            )
            .orderBy(["reference"], ["desc"])
            .value();
        }

        return _.chain(this.hwContracts)
          .orderBy(["reference"], ["desc"])
          .value();
      }
      return null;
    },
  },
});
