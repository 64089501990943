import appConfig from "@/app.config";
import IEmployeeContactsRepository from "@/core/employees/contacts/iemployee-contacts.repository";
import HttpRepository from "@/infrastructure/http.repository";
import IEmployeeARHModel from "@/models/employees/contacts/iemployee-arh-model";
import IEmployeeReferentModel from "@/models/employees/contacts/iemployee-referent-model";

export default class EmployeeContactsRepository
  extends HttpRepository
  implements IEmployeeContactsRepository {

  private webApiUrls = {
    getContactReferent: `${appConfig.webApiBaseUrl}/EmployeeContactsReferent/GetEmployeeContactsReferent`,
    getContactARH: `${appConfig.webApiBaseUrl}/EmployeeContactsReferent/GetEmployeeContactsARH`
  }

  public async getContactReferent(): Promise<IEmployeeReferentModel> {
    return this.httpGet(this.webApiUrls.getContactReferent);
  }

  public async getContactARH(): Promise<IEmployeeARHModel> {
    return this.httpGet(this.webApiUrls.getContactARH);
  }
}