import BabySitterRenewalContractModel from "@/models/babysitter/babysitter-renewal-contract-model";
import IBabysitterRenewalRepository from "./ibabysitter-renewal.repository";
import { EmployeeRenewalLinkedCustomersModel } from "@/models/babysitter/renewal/employee-renewal-linked-customer-model";
import { HttpResponseData } from "@/models/common/http-response-data";

export default class BabysitterRenewalService {
  private _babysitterRenewalRepository: IBabysitterRenewalRepository;

  public constructor(babysitterRenewalRepository: IBabysitterRenewalRepository) {
    this._babysitterRenewalRepository = babysitterRenewalRepository;
  }

  public sendReminder(model: BabySitterRenewalContractModel): Promise<any> {
    return this._babysitterRenewalRepository.sendReminder(model);
  }

  public getLinkedFamilies(): Promise<HttpResponseData<EmployeeRenewalLinkedCustomersModel>> {
    return this._babysitterRenewalRepository.getLinkedFamilies();
  }
}
