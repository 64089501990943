








































import appFactories from "@/app.factories";
import { i18n } from "@/app/helpers/plugins/i18n";
import Vue from "vue";
export default Vue.extend({
  name: "UserSettingsLanguageSelector",

  data() {
    return {
      items: this.$i18n.availableLocales,
      locale: this.$i18n.locale,
    };
  },

  methods: {
    localeIcon(locale: string): string {
      const fallbackLocale = i18n.fallbackLocale;
      switch (locale) {
        case "fr":
          return require("@/assets/images/lang/fr.webp");
        case "en":
          return require("@/assets/images/lang/us.webp");
        default:
          return require(`@/assets/images/lang/${fallbackLocale}.webp`);
      }
    },
    setLocale() {
      appFactories.services.localStorage.setLanguage(this.locale);
      i18n.locale = this.locale;
      this.$vuetify.lang.current = this.locale;
    },
  },
});
