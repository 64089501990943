import { render, staticRenderFns } from "./ContactsCardInfo.vue?vue&type=template&id=80db21f4&"
import script from "./ContactsCardInfo.vue?vue&type=script&lang=ts&"
export * from "./ContactsCardInfo.vue?vue&type=script&lang=ts&"
import style0 from "./ContactsCardInfo.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports