


















































































import Vue from "vue";
import { storeActions, storeGetters } from "../../../store";
import ConfirmModal from "@/app/components/common/ConfirmModal.vue";
import { mapGetters } from "vuex";
import appConfig from "@/app.config";
import AvatarEditorImage from "@/app/babysitter/components/profile/AvatarEditorImage.vue";
import {
  IMAGE_ALLOWED_TYPES,
  FormValidators,
} from "@/app/helpers/forms/form.validators";
export default Vue.extend({
  name: "AvatarEditor",

  components: {
    ConfirmModal,
    AvatarEditorImage,
  },

  data() {
    return {
      showConfirmDeleteAvatar: false,
      photoPickerIsOpen: false,
      photoGuideUrl: appConfig.photoGuideUrl,
      uploadAllowedFileTypes: IMAGE_ALLOWED_TYPES,
      uploadFile: undefined,
      uploadRules: [
        FormValidators.required,
        FormValidators.imageSize,
        FormValidators.imageType,
      ],
    };
  },

  computed: {
    ...mapGetters({
      avatarUrl: storeGetters.user.avatarUrl,
      hasAvatar: storeGetters.user.hasAvatar,
    }),
  },

  methods: {
    togglePhotoPicker() {
      this.uploadFile = undefined;
      this.photoPickerIsOpen = !this.photoPickerIsOpen;
    },

    async deletePhoto() {
      this.showConfirmDeleteAvatar = false;
      await this.$store.dispatch(storeActions.user.deleteAvatar);
      this.$emit("avatarUpdated");
    },

    async uploadPhoto() {
      const form = this.$refs.form as any;
      if (!form.validate()) {
        return;
      }

      await this.$store.dispatch(
        storeActions.user.updateAvatar,
        this.uploadFile
      );
      this.$emit("avatarUpdated");
      this.togglePhotoPicker();
    },
  },
});
