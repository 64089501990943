import { FamilyRenewalChoiceModel } from "./renewal/family-renewal-choice-model";
import { NotRenewingReasonsEnum } from "./renewal/not-renewing-reasons-enum";

export default class BabySitterRenewalContractModel {
  public needRenewContract?: boolean;
  public notRenewingReason?: NotRenewingReasonsEnum;
  public knowAvailabilitiesBackToSchool?: boolean;
  public knownAvailabilityDate?: Date;
  public bsID: number;
  public familiesRenewalChoices: FamilyRenewalChoiceModel[]

  public constructor() {
    this.needRenewContract = null;
    this.notRenewingReason = null;
    this.knowAvailabilitiesBackToSchool = null;
    this.knownAvailabilityDate = null;
    this.familiesRenewalChoices = [];
  }
}
