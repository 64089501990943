













































import EditableContainer from "@/app/components/common/EditableContainer.vue";
import { getEnumValues, getEnumFlags } from "@/core/helpers/enum.helper";
import { BabySitterSearchFrequencyEnum } from "@/models/babysitter/profile/babysitter-search-frequency.enum";
import Vue from "vue";
export default Vue.extend({
  components: { EditableContainer },
  name: "SearchFrequency",
  props: {
    searchFrequency: Number,
  },
  data() {
    return {
      enumValues: getEnumValues(BabySitterSearchFrequencyEnum).filter(
        (_) =>
          _ !== BabySitterSearchFrequencyEnum.None
      ),
      model: {
        // We need to add +1 to value because the first value enum is 0 and the checkbox will treat it as false
        searchFrequencies: getEnumFlags(BabySitterSearchFrequencyEnum, this.searchFrequency)
      },
    };
  },
  methods: {
    revert() {
      this.model.searchFrequencies = [];
    },
    saveFrequencies() {
      let searchFrequencyResult = 0;
      this.model.searchFrequencies.forEach(elem => searchFrequencyResult += elem);
      
      this.$emit("save", {
        searchFrequency: searchFrequencyResult,
      });
    }
  },
});
