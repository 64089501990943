










































































































































































import Vue, { PropType } from "vue";
import IBabySitterExperienceModel from "@/models/babysitter/profile/ibabysitter-experience-model";
import {
  getEnumValues,
  getEnumValuesOrderByLabel,
} from "@/core/helpers/enum.helper";
import {
  BabySitterExperienceCategorieEnum,
  ChildrenAgeCategoryEnum,
  BabySitterExperienceDurationEnum,
  BabySitterExperienceGuardTypeEnum,
  ChildrenNumCategoryEnum,
} from "@/models/babysitter/profile/babysitter-experience-enums";
import ConfirmModal from "@/app/components/common/ConfirmModal.vue";
import EditableContainer from "@/app/components/common/EditableContainer.vue";
import { translate } from "@/app/helpers/plugins/i18n";

export default Vue.extend({
  name: "Experience",
  props: {
    experience: Object as PropType<IBabySitterExperienceModel>,
    experienceNumer: Number,
    editable: Boolean,
  },
  components: {
    EditableContainer,
    ConfirmModal,
  },
  data() {
    return {
      experienceType: this.experience.typologieCategorie,
      childAge: this.experience.typologieCategorieAge,
      duration: this.experience.typologieDuree,
      guardType: this.experience.guardType,
      description: this.experience.description,
      nombreEnfant: this.experience.nombreEnfant,
      isEditing: this.experience.id === 0,
      showConfirmDeleteExperience: false,
    };
  },
  computed: {
    experienceTypeEnumValues() {
      return getEnumValuesOrderByLabel(
        BabySitterExperienceCategorieEnum,
        translate,
        "Enums.BabySitterExperienceCategorieEnum"
      );
    },
    childAgeCategoryEnumValues() {
      return getEnumValues(ChildrenAgeCategoryEnum);
    },
    guardTypeEnumValues() {
      return getEnumValues(BabySitterExperienceGuardTypeEnum);
    },
    durationTypeEnumValues() {
      return getEnumValues(BabySitterExperienceDurationEnum);
    },
    ChildrenNumCategoryEnumValues() {
      return getEnumValues(ChildrenNumCategoryEnum);
    },
    canSave(): boolean {
      return (
        (this.experienceType !== null &&
        this.childAge !== null &&
        this.duration !== null &&
        this.guardType !== null) ||
        this.description !== null
      );
    },
  },
  methods: {
    toLocaleDate(date:Date): string
    {
      return new Date(date.getTime() - new Date().getTimezoneOffset() * 60 * 1000).toISOString();
    },
    revert() {
      this.experienceType = this.experience.typologieCategorie;
      this.childAge = this.experience.typologieCategorieAge;
      this.duration = this.experience.typologieDuree;
      this.guardType = this.experience.guardType;
      this.description = this.experience.description;
      this.nombreEnfant = this.experience.nombreEnfant;
      this.$emit("cancel", this.experience.id);
      this.toggleEdit();
    },
    saveExperience() {
      if (this.experience.id === 0) {
        this.experience.id = Date.now() & 0xffffffff;
      }
      this.experience.typologieCategorie = this.experienceType;
      this.experience.typologieCategorieAge = this.childAge;
      this.experience.typologieDuree = this.duration;
      this.experience.guardType = this.guardType;
      this.experience.description = this.description;
      this.experience.dateOfModification = this.toLocaleDate(new Date()); 
      this.experience.nombreEnfant = +this.nombreEnfant;
      this.toggleEdit();
      this.$emit("save", this.experience);
    },
    toggleEdit() {
      this.isEditing = !this.isEditing;
    },
    removeExperience() {
      this.$emit("remove", this.experience.id);
    },
    confirmRemoveExperience() {
      this.showConfirmDeleteExperience = true;
    },
  },
});
