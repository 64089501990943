
























































































































import appFactories from "@/app.factories";
import { FormValidators } from "@/app/helpers/forms/form.validators";
import { storeActions } from "@/app/store";
import Vue from "vue";
import { mapState } from "vuex";
export default Vue.extend({
  name: "UserSettingsPassword",

  data() {
    return {
      currentPassword: "",
      newPassword: "",
      newPasswordConfirmation: "",
      validationRules: {
        currentPassword: [FormValidators.required],
        newPassword: [FormValidators.required],
        newPasswordConfirmation: [
          FormValidators.required,
          (this as any).checkNewPasswordConfirmation,
        ],
      },
    };
  },

  computed: {
    ...mapState({
      isLoading: (state: any) => state.global.isLoading,
      userEmail: (state: any) => state.user.email as string,
    }),
    newPasswordDoesNotContainEmailPrefix(): boolean {
      return (
        this.newPassword &&
        !this.newPassword.includes(this.userEmail.split("@")[0])
      );
    },
    newPasswordIsRegexValid(): boolean {
      return /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,64})/.test(
        this.newPassword
      );
    },
    newPasswordIsValidWithConfirmation(): boolean {
      return (
        this.newPassword &&
        this.newPasswordConfirmation &&
        this.newPassword === this.newPasswordConfirmation
      );
    },
    formIsValid(): boolean {
      return (
        !!this.currentPassword &&
        !!this.newPassword &&
        !!this.newPasswordConfirmation &&
        this.newPasswordDoesNotContainEmailPrefix &&
        this.newPasswordIsRegexValid &&
        this.newPasswordIsValidWithConfirmation
      );
    },
  },

  methods: {
    checkNewPasswordConfirmation() {
      return (
        this.newPasswordIsValidWithConfirmation ||
        this.$i18n.t("UserSettingsPassword.checkNewPasswordConfirmation")
      );
    },

    async updatePassword() {
      if (this.isLoading) {
        return;
      }

      if (this.formIsValid) {
        await this.$store.dispatch(storeActions.global.isLoading, true);
        try {
          await appFactories.services.auth.updatePassword(
            this.currentPassword,
            this.newPassword
          );
          this.$store.dispatch(storeActions.global.showSuccess, {
            message: this.$i18n.t("UserSettingsPassword.actionConfirmation"),
          });
          this.$store.dispatch(storeActions.user.clear);
          this.$router.replace("/login");
        } finally {
          await this.$store.dispatch(storeActions.global.isLoading, false);
        }
      }
    },
  },
});
