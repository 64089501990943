import IUserProfileInformationResponse from "@/models/user/user-profile-information";
import IAuthRepository from "./iauth.repository";

export default class AuthService {
  private _authRepository: IAuthRepository;

  public constructor(authRepository: IAuthRepository) {
    this._authRepository = authRepository;
  }

  public getLoginUrl(): string {
    return this._authRepository.getLoginUrl();
  }

  public logout(): Promise<void> {
    return this._authRepository.logout();
  }

  public getMyInformation(): Promise<IUserProfileInformationResponse> {
    return this._authRepository.getMyInformation();
  }

  public updatePassword(currentPassword: string, newPassword: string): Promise<void> {
    return this._authRepository.updatePassword(currentPassword, newPassword);
  }

  public requestResetPassword(email: string): Promise<void> {
    return this._authRepository.requestResetPassword(email);
  }

  public resetPassword(password: string, resetKey: string): Promise<void> {
    return this._authRepository.resetPassword(password, resetKey);
  }

  public deleteAccount(): Promise<void> {
    return this._authRepository.deleteAccount();
  }
}
