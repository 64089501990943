import BabySitterRenewalContractModel from "@/models/babysitter/babysitter-renewal-contract-model";
import IBabysitterRenewalRepository from "@/core/babysitter/renewal/ibabysitter-renewal.repository";
import appConfig from "@/app.config";
import HttpRepository from "@/infrastructure/http.repository";
import { EmployeeRenewalLinkedCustomersModel } from "@/models/babysitter/renewal/employee-renewal-linked-customer-model";
import { HttpResponseData } from "@/models/common/http-response-data";

export default class BabysitterRenewalRepository
  extends HttpRepository
  implements IBabysitterRenewalRepository {

  private webApiUrls = {
    sendReminder: `${appConfig.webApiBaseUrl}/BabysitterRenewal/SendBackToSchoolMessage`,
    getLinkedFamilies: `${appConfig.webApiBaseUrl}/BabysitterRenewal/LinkedFamilies`
  };

  public sendReminder(model: BabySitterRenewalContractModel): Promise<any> {
    return this.httpPost(this.webApiUrls.sendReminder, {
      "NeedRenewContract": model.needRenewContract,
      "NotRenewingReason": model.notRenewingReason,
      "KnowAvailabilitiesBackToSchool": model.knowAvailabilitiesBackToSchool,
      "KnowAvailabilityDate": model.knownAvailabilityDate,
      "BabySitterID": model.bsID,
      "FamiliesRenewalChoices": model.familiesRenewalChoices
    });
  }

  public getLinkedFamilies():  Promise<HttpResponseData<EmployeeRenewalLinkedCustomersModel>> {
    return this.httpGet(this.webApiUrls.getLinkedFamilies);
  }
}
