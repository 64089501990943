import IContactsCatalog from "@/core/employees/contacts/icontacts-catalog";
import IEmployeeContactsModel from "@/models/employees/contacts/iemployee-contacts-model";

export default class EmployeeContactsCatalog implements IContactsCatalog {

  private employeeContactsSections = (): IEmployeeContactsModel[] => [
    {
      id: 0,
      title: "Catalogs.BabysitterAgencySections.contacts.arh.title",
      description: "Catalogs.BabysitterAgencySections.contacts.arh.desc",
      info3: "Catalogs.BabysitterAgencySections.contacts.arh.mail",
      isStandard: true,
    },
    {
      id: 1,
      title: "Catalogs.BabysitterAgencySections.contacts.refSecteur.title",
      description: "Catalogs.BabysitterAgencySections.contacts.refSecteur.desc",
      info3: "Catalogs.BabysitterAgencySections.contacts.refSecteur.mail",
      isStandard: true,
    },
    {
      id: 2,
      title: "Catalogs.BabysitterAgencySections.contacts.reclamation.title",
      description: "Catalogs.BabysitterAgencySections.contacts.reclamation.desc",
      info3: "Catalogs.BabysitterAgencySections.contacts.reclamation.mail",
      isStandard: true,
    },
    {
      id: 3,
      title: "Catalogs.BabysitterAgencySections.contacts.numUrgence.title",
      description: "Catalogs.BabysitterAgencySections.contacts.numUrgence.desc",
      info2: "Catalogs.BabysitterAgencySections.contacts.numUrgence.tel",
      isStandard: true,
    },
    {
      id: 4,
      title: "Catalogs.BabysitterAgencySections.contacts.adresseARH.title",
      info1: "Catalogs.BabysitterAgencySections.contacts.adresseARH.service",
      info2: "Catalogs.BabysitterAgencySections.contacts.adresseARH.adresse",
      info3: "Catalogs.BabysitterAgencySections.contacts.adresseARH.codePostal",
    },
    {
      id: 5,
      title: "Catalogs.BabysitterAgencySections.contacts.ecrire.title",
      info1: "Catalogs.BabysitterAgencySections.contacts.ecrire.service",
      info2: "Catalogs.BabysitterAgencySections.contacts.ecrire.cs",
      info3: "Catalogs.BabysitterAgencySections.contacts.ecrire.codePostal",
    },
  ];

  public getContacts(): IEmployeeContactsModel[] {
    return this.employeeContactsSections();
  }
}
