import IEmployeeContactsModel from "@/models/employees/contacts/iemployee-contacts-model";
import IContactsCatalog from "./icontacts-catalog";
import IEmployeeContactsRepository from "./iemployee-contacts.repository";
import IEmployeeReferentModel from "@/models/employees/contacts/iemployee-referent-model";
import IEmployeeARHModel from "@/models/employees/contacts/iemployee-arh-model";

export default class ContactsService {
  private _contactsCatalog: IContactsCatalog;
  private _contactsRepository : IEmployeeContactsRepository

  public constructor(contactsCatalog: IContactsCatalog, contactsRepository: IEmployeeContactsRepository) {
    this._contactsCatalog = contactsCatalog;
    this._contactsRepository = contactsRepository;
  }

  public getContacts(): IEmployeeContactsModel[] {
    return this._contactsCatalog.getContacts();
  }

  public getContactsReferent(): Promise<IEmployeeReferentModel>{
    return this._contactsRepository.getContactReferent();
  }

  public getContactARH(): Promise<IEmployeeARHModel> {
    return this._contactsRepository.getContactARH();
  }
}
