import { BabySitterProfessionalSituationEnum } from "@/models/babysitter/profile/babysitter-professional-situation.enum";
import { BabySitterSearchFrequencyEnum } from "@/models/babysitter/profile/babysitter-search-frequency.enum";
import { CriteriaDiplomasEnum, CriteriaEducationLevelEnum, CriteriaEducationThemeEnum, CriteriaSpokenLanguageEnum } from "@/models/babysitter/profile/criteria.enum";
import IAvailabilityModel from "@/models/babysitter/profile/ibabysitter-availability-model";
import IBabySitterExperienceModel from "@/models/babysitter/profile/ibabysitter-experience-model";
import IBabySitterProfileModel from "@/models/babysitter/profile/ibabysitter-profile-model";
import { IHierarchyTextValueCodeItem } from "@/models/common/IhierarchyTextValueCodeItem-model";
import IBabysitterProfileRepository from "./ibabysitter-profile.repository";

export default class BabysitterProfileService {
  private _babysitterProfileRepository: IBabysitterProfileRepository;

  public constructor(babysitterProfileRepository: IBabysitterProfileRepository) {
    this._babysitterProfileRepository = babysitterProfileRepository;
  }

  public getProfile(): Promise<IBabySitterProfileModel> {
    return this._babysitterProfileRepository.getProfile();
  }

  public updateAvatar(file: File): Promise<string> {
    return this._babysitterProfileRepository.updateAvatar(file);
  }

  public deleteAvatar(): Promise<void> {
    return this._babysitterProfileRepository.deleteAvatar();
  }

  public saveExperiences(babySitterId: number, experiences: IBabySitterExperienceModel[]): Promise<any> {
    return this._babysitterProfileRepository.saveExperiences(babySitterId, experiences)
  }

  public saveAvailabilities(availabilities: IAvailabilityModel[]): Promise<any> {
    return this._babysitterProfileRepository.saveAvailabilities(availabilities);
  }

  public saveGuardTypes(availableForHousework: boolean, hasHomeworkHelpOption: boolean, hasIroningOption: boolean): Promise<any> {
    return this._babysitterProfileRepository.saveGuardTypes(availableForHousework, hasHomeworkHelpOption, hasIroningOption);
  }

  public updateAdvertPublication(publish: boolean) {
    return this._babysitterProfileRepository.updateAdvertPublication(publish);
  }

  public updateEducation(educationLevel: CriteriaEducationLevelEnum, educationTheme: CriteriaEducationThemeEnum, diplomas: CriteriaDiplomasEnum[]): Promise<any> {
    return this._babysitterProfileRepository.saveEducation(educationLevel, educationTheme, diplomas);
  }

  public updateTransport(hasDrivingLicence: boolean, canConveyChildren: boolean): Promise<any> {
    return this._babysitterProfileRepository.saveTransport(hasDrivingLicence, canConveyChildren)
  }

  public updateSpokenLanguages(langs: CriteriaSpokenLanguageEnum[]): Promise<any> {
    return this._babysitterProfileRepository.saveLanguages(langs);
  }

  public updateAdvert(title: string, description: string): Promise<any> {
    return this._babysitterProfileRepository.saveAdvert(title, description);
  }

  public updateProfessionalSituation(professionalSituation: BabySitterProfessionalSituationEnum): Promise<any> {
    return this._babysitterProfileRepository.updateProfessionalSituation(professionalSituation);
  }

  public getProfileCompletionRate(): Promise<any> {
    return this._babysitterProfileRepository.getProfileCompletionRate();
  }

  public getDiplomaList(): Promise<IHierarchyTextValueCodeItem[]> {
    return this._babysitterProfileRepository.getDiplomaList();
  }

  public updateSearchFrequency(searchFrequency: BabySitterSearchFrequencyEnum): Promise<any> {
    return this._babysitterProfileRepository.updateSearchFrequency(searchFrequency);
  }
}
