












































































import Vue from "vue";
import { storeActions, storeGetters }  from "@/app/store";
import factories from "@/app.factories";
import IBabySitterProfileModel from "@/models/babysitter/profile/ibabysitter-profile-model";
import Resume from "@/app/babysitter/components/profile/Resume.vue";
import EducationSection from "@/app/babysitter/components/profile/EducationSection.vue";
import Availability from "@/app/babysitter/components/profile/Availability.vue";
import BabySitterExperiences from "@/app/babysitter/components/profile/BabySitterExperiences.vue";
import ProfileProgressionPanel from "@/app/babysitter/components/profile/ProfileProgressionPanel.vue";
import CareService from "@/app/babysitter/components/profile/CareServices.vue";
import Transport from "@/app/babysitter/components/profile/Transport.vue";
import SpokenLanguage from "@/app/babysitter/components/profile/SpokenLanguage.vue";
import NavigablePage from "@/app/components/common/NavigablePage.vue";
import { mapState } from "vuex";
import IAvailabilityModel from "@/models/babysitter/profile/ibabysitter-availability-model";
import { CriteriaSpokenLanguageEnum } from "@/models/babysitter/profile/criteria.enum";
import ProfessionalSituation from "../components/profile/ProfessionalSituation.vue";
import ProfilePageLoading from "../components/profile/ProfilePageLoading.vue";
import SearchFrequency from "../components/profile/SearchFrequency.vue";

export default Vue.extend({
  name: "BabysitterProfilePage",
  components: {
    Resume,
    EducationSection,
    Availability,
    BabySitterExperiences,
    ProfileProgressionPanel,
    CareService,
    Transport,
    SpokenLanguage,
    NavigablePage,
    ProfessionalSituation,
    ProfilePageLoading,
    SearchFrequency
  },

  data() {
    return {
      profile: undefined as IBabySitterProfileModel,
      isDisplayedInformationBanner: this.$store.getters[storeGetters.user.isDisplayedInformationBanner]
    };
  },

  async mounted() {
    await this.loadProfile();
  },

  methods: {
    async loadProfile() {
      this.$store.dispatch(storeActions.global.isLoading, true);
      try {
        this.profile = await factories.services.babysitter.profile.getProfile();
      } finally {
        this.$store.dispatch(storeActions.global.isLoading, false);
      }
    },
    onSaveExperiences() {
      factories.services.babysitter.profile.saveExperiences(
        this.babysitterId,
        this.profile.experiences
      );
    },
    async onSaveAvailabilities(newAvailabilities: IAvailabilityModel[]) {
      this.profile.availabilities = newAvailabilities;
      await factories.services.babysitter.profile.saveAvailabilities(
        newAvailabilities
      );
      await this.loadProfile();
    },
    onSaveCareServices(careServicesOptions: any) {
      this.profile.isAvailableForHousework =
        careServicesOptions.availableForHousework;
      this.profile.hasHomeworkHelpOption =
        careServicesOptions.hasHomeworkHelpOption;
      this.profile.hasIroningOption = careServicesOptions.hasIroningOption;

      factories.services.babysitter.profile.saveGuardTypes(
        this.profile.isAvailableForHousework,
        this.profile.hasHomeworkHelpOption,
        this.profile.hasIroningOption
      );
    },
    updateAdvertPublication(value: boolean) {
      this.profile.advertIsPublished = value;
      factories.services.babysitter.profile.updateAdvertPublication(value);
    },
    onSaveEducation(educationSettings: any) {
      this.profile.educationTheme = educationSettings.educationTheme;
      this.profile.educationLevel = educationSettings.educationLevel;
      this.profile.specificDiplomas = educationSettings.specificDiplomas;
      factories.services.babysitter.profile.updateEducation(
        this.profile.educationLevel,
        this.profile.educationTheme,
        this.profile.specificDiplomas
      );
    },
    onSaveProfessionalSituation(model: any) {
      this.profile.professionalSituation = model.professionalSituation;
      factories.services.babysitter.profile.updateProfessionalSituation(
        model.professionalSituation
      );
    },
    onSaveSearchFrequency(model: any) {
      this.profile.searchFrequency = model.searchFrequency;
      factories.services.babysitter.profile.updateSearchFrequency(
        model.searchFrequency
      );
    },
    async onSaveTransport(transportSettings: any) {
      this.profile.hasDrivingLicence = transportSettings.hasDrivingLicence;
      this.profile.canConveyChildren = transportSettings.canConveyChildren;
      await factories.services.babysitter.profile.updateTransport(
        this.profile.hasDrivingLicence,
        this.profile.canConveyChildren
      );
      await this.refreshCompletionRate();
    },
    onSaveLanguages(spokenLanguages: CriteriaSpokenLanguageEnum[]) {
      this.profile.spokenLanguages = spokenLanguages;
      factories.services.babysitter.profile.updateSpokenLanguages(
        this.profile.spokenLanguages
      );
    },

    async onSaveAdvert(advertSettings: any) {
      this.profile.advertTitle = advertSettings.advertTitle;
      this.profile.advertDescription = advertSettings.advertDescription;
      await factories.services.babysitter.profile.updateAdvert(
        this.profile.advertTitle,
        this.profile.advertDescription
      );
      await this.refreshCompletionRate();
    },

    async refreshCompletionRate() {
      const completionRateResponse =
        await factories.services.babysitter.profile.getProfileCompletionRate();
      this.profile.profileCompletionRate =
        completionRateResponse.completionRate;
    },
  },
  computed: {
    ...mapState({
      babysitterId: (state: any) => state.user.id,
    }),
  },
});
