var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('footer',{staticClass:"mt-10 border-top pa-6"},[_c('v-row',{staticClass:"mt-10 mb-5"},[_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"5","offset-lg":"1"}},[_c('img',{attrs:{"src":require('@/assets/images/logo.svg'),"height":"36"}}),_c('p',{staticClass:"my-6"},[_vm._v(" "+_vm._s(_vm.$t("AppFooter.description"))+" ")]),_c('ul',{staticClass:"social"},[_c('li',{staticClass:"mr-3"},[_c('a',{attrs:{"href":"https://www.facebook.com/yoopala","target":"_blank"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-icon',{attrs:{"color":hover ? 'primary' : ''}},[_vm._v("mdi-facebook")])]}}])})],1)]),_c('li',{staticClass:"ma-3"},[_c('a',{attrs:{"href":"https://www.instagram.com/yoopalaservices/","target":"_blank"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-icon',{attrs:{"color":hover ? 'primary' : ''}},[_vm._v("mdi-instagram")])]}}])})],1)]),_c('li',{staticClass:"ma-3"},[_c('a',{attrs:{"href":"https://twitter.com/yoopalaservices","target":"_blank"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-icon',{attrs:{"color":hover ? 'primary' : ''}},[_vm._v("mdi-twitter")])]}}])})],1)]),_c('li',{staticClass:"ma-3"},[_c('a',{staticClass:"align-icon",attrs:{"href":"https://www.tiktok.com/@yoopala_services","target":"_blank"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('svg',{attrs:{"fill":hover ? '#ff9e1b' : 'grey',"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 50 50","width":"24px"}},[_c('path',{attrs:{"d":"M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z"}})])]}}])})],1)]),_c('li',{staticClass:"ma-3"},[_c('a',{attrs:{"href":"https://www.youtube.com/channel/UCcoNEfa-Z-kY5AUIVZQsM1g","target":"_blank"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-icon',{attrs:{"color":hover ? 'primary' : ''}},[_vm._v("mdi-youtube")])]}}])})],1)]),_c('li',{staticClass:"ma-3"},[_c('a',{attrs:{"href":"https://fr.linkedin.com/company/yoopala","target":"_blank"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-icon',{attrs:{"color":hover ? 'primary' : ''}},[_vm._v("mdi-linkedin")])]}}])})],1)])])]),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('h3',[_vm._v(_vm._s(_vm.$t("AppFooter.Société")))]),_c('ul',[_c('li',{staticClass:"my-2"},[_c('a',{attrs:{"href":"https://assistance.yoopala.com/fr/category/yc4w2a/"}},[_vm._v(_vm._s(_vm.$t("AppFooter.Nous contacter")))])]),_c('li',{staticClass:"my-2"},[_c('a',{attrs:{"href":"https://assistance.yoopala.com/fr/"}},[_vm._v(_vm._s(_vm.$t("AppFooter.Assistance en ligne")))])]),_c('li',{staticClass:"my-2"},[_c('a',{attrs:{"href":"https://carrieres.yoopala.com/"}},[_vm._v(_vm._s(_vm.$t("AppFooter.Carrières")))])])])]),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('h3',[_vm._v(_vm._s(_vm.$t("AppFooter.Informations")))]),_c('ul',[_c('li',{staticClass:"my-2"},[_c('a',{attrs:{"href":"https://www.yoopala.com/ressources/mentions-legales/"}},[_vm._v(_vm._s(_vm.$t("AppFooter.Mentions légales")))])]),_c('li',{staticClass:"my-2"},[_c('a',{attrs:{"href":"https://www.yoopala.com/ressources/politique-protection-donnees/"}},[_vm._v(_vm._s(_vm.$t("AppFooter.Politique de protection des données")))])])])])],1),_c('v-row',[_c('v-col',[_c('div',{staticClass:"border-top d-flex flex-wrap justify-center partners pt-5"},[_c('img',{attrs:{"src":require('@/assets/images/partners/cesu.png')}}),_c('img',{attrs:{"src":require('@/assets/images/partners/service-particuliers.png')}}),_c('img',{attrs:{"src":require('@/assets/images/partners/charte-qualite.png')}}),_c('img',{attrs:{"src":require('@/assets/images/partners/service-personne.png')}})])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"10","offset-lg":"1"}},[_c('p',{staticClass:"legal"},[_vm._v(" "+_vm._s(_vm.$t("AppFooter.legal"))+" ")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }