export const headerMenuLinksCatalog = [
  {
    title: "Pour les familles",
    links: [
      { title: "Nos solutions de garde", link: "https://www.yoopala.com/ressources/categorie/familles/solutions-de-garde/", absolute: true },
      { title: "Comment ça marche ?", link: "https://www.yoopala.com/ressources/fonctionnement-familles/", absolute: true },
      { title: "Combien ça coûte ?", link: "https://www.yoopala.com/ressources/tarifs/", absolute: true },
      { title: "Obtenir un devis", link: "/inscription/compte" },
      { title: "Nos contenus", link: "https://www.yoopala.com/ressources/categorie/familles/", absolute: true  }
    ]
  },
  {
    title: "Pour les nounous",
    links: [
      { title: "Comment ça marche ?", link: "https://www.yoopala.com/ressources/fonctionnement-nounous/", absolute: true },
      { title: "Rémunération et avantages", link: "https://www.yoopala.com/ressources/remuneration/", absolute: true },
      { title: "Voir les jobs disponibles", link: "/inscription/compte" },
      { title: "Nos contenus", link: "https://www.yoopala.com/ressources/categorie/nounous/", absolute: true },
    ]
  },
  {
    title: "Nos contenus",
    links: [
      { title: "Pour les familles", link: "https://www.yoopala.com/ressources/categorie/familles/", absolute: true },
      { title: "Pour les nounous", link: "https://www.yoopala.com/ressources/categorie/nounous/", absolute: true },
      { title: "Pour les enfants", link: "https://www.yoopala.com/ressources/categorie/enfants/", absolute: true },
      { title: "Chroniques de maman Cha ", link: "https://www.yoopala.com/ressources/categorie/chroniques-maman-cha/", absolute: true }
    ]
  }
];
