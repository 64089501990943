var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{staticClass:"pt-8",attrs:{"app":"","flat":"","color":"transparent","absolute":""}},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"xs":"12","sm":"1","md":"3","lg":"2","xl":"1","offset-xl":"1"}},[_c('a',{attrs:{"href":_vm.headerIconUrl}},[_c('img',{staticClass:"ma-4",attrs:{"src":require('@/assets/images/logo.svg'),"height":"36"}})])]),_c('v-col',{staticClass:"justify-end d-none d-md-flex",attrs:{"xs":"12","sm":"11","md":"9","lg":"7","offset-lg":"3","xl":"5","offset-xl":"4"}},[_c('v-sheet',{staticClass:"menu-box pa-4 rounded-lg d-flex align-center"},[_c('wisp-widget',{staticClass:"mx-1"}),_vm._l((_vm.yoopalaLinks),function(menu){return _c('v-menu',{key:menu.title,attrs:{"open-on-hover":"","bottom":"","offset-y":""},on:{"input":_vm.closeWispWidgetIfOpen},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
var value = ref.value;
return [_c('span',_vm._g(_vm._b({staticClass:"px-4",class:{ 'primary--text': value }},'span',attrs,false),on),[_vm._v(" "+_vm._s(menu.title)+" ")])]}}],null,true)},[_c('v-list',_vm._l((menu.links),function(link){return _c('v-list-item',{key:link.title},[_c('a',{staticClass:"unstyled-link",attrs:{"href":link.absolute ? link.link : _vm.wwwBaseUrl + link.link}},[_vm._v(" "+_vm._s(link.title)+" ")])])}),1)],1)}),_c('v-menu',{attrs:{"open-on-hover":"","bottom":"","offset-y":""},on:{"input":_vm.closeWispWidgetIfOpen},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
var value = ref.value;
return [_c('span',_vm._g(_vm._b({staticClass:"px-4",class:{ 'primary--text': value }},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t("AppMenu.MonEspace"))+" ")])]}}])},[_c('v-list',[_c('v-list-item',{attrs:{"to":"/accueil","active-class":"primary--text"}},[_c('a',{staticClass:"unstyled-link"},[_vm._v(" "+_vm._s(_vm.$t("Header.dashboard"))+" ")])]),_vm._l((_vm.userLinks),function(link){return _c('v-list-item',{key:link.title,attrs:{"to":link.navigateTo,"active-class":"primary--text"}},[_c('a',{staticClass:"unstyled-link"},[_vm._v(" "+_vm._s(_vm.$t(link.title))+" ")])])}),_c('v-list-item',{attrs:{"to":_vm.settingsPageUrl,"active-class":"primary--text"}},[_c('a',{staticClass:"unstyled-link"},[_vm._v(" "+_vm._s(_vm.$t("UserHeaderMenu.UserSettings"))+" ")])]),_c('v-list-item',{on:{"click":function () { return _vm.$emit('logout'); }}},[_c('a',{staticClass:"unstyled-link"},[_vm._v(" "+_vm._s(_vm.$t("UserHeaderMenu.SignOut"))+" ")])])],2)],1)],2)],1),_c('v-col',{staticClass:"justify-end d-flex d-md-none",attrs:{"xs":"12","sm":"11","md":"9","lg":"7","offset-lg":"3","xl":"5","offset-xl":"4"}},[_c('v-sheet',{staticClass:"menu-box pa-1 rounded-lg d-flex align-center"},[_c('wisp-widget'),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.openMobileMenu}},[_c('img',{attrs:{"src":require('@/assets/images/header/burger-menu.svg')}})])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }