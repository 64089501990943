









































































































































import Vue from "vue";
import { closeWispWidgetIfOpen } from "@/app/components/layout/WispWidget.vue";
import WispWidget from "./WispWidget.vue";
import appConfig from "@/app.config";

export default Vue.extend({
  name: "AppHeaderBar",

  components: {
    WispWidget,
  },

  props: ["yoopalaLinks", "userLinks", "wwwBaseUrl", "settingsPageUrl"],

  methods: {
    openMobileMenu() {
      this.closeWispWidgetIfOpen();
      this.$emit("openMobileMenu");
    },

    closeWispWidgetIfOpen: closeWispWidgetIfOpen,
  },

  computed: {
    headerIconUrl() {
      return appConfig.wwwBaseUrl;
    },
  },
});
