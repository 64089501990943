
































import Vue from "vue";
import { storeActions, storeGetters }  from "@/app/store";
import factories from "@/app.factories";
import NavigablePage from "@/app/components/common/NavigablePage.vue";
import ContactsCard from "@/app/babysitter/components/contacts/ContactsCard.vue"

export default Vue.extend({
  name: "EmployeeContactsPage",
  components: {
    NavigablePage,
    ContactsCard,
  },

  data() {
    return {
      contacts: undefined,
      isDisplayedInformationBanner: this.$store.getters[storeGetters.user.isDisplayedInformationBanner],
    }
  },

  async mounted() {
    await this.loadContacts();
  },

  methods: {
    async loadContacts() {
      await this.$store.dispatch(storeActions.global.isLoading, true);
      try {
        this.contacts = factories.services.employee.contacts.getContacts();
      } finally {
        await this.$store.dispatch(storeActions.global.isLoading, false);
      }
    },
  }

})
