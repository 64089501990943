import { parseJSON, format, isValid } from 'date-fns'
import Vue from "vue";
import { fr } from 'date-fns/locale'

export const jsonDatetoDate = Vue.filter('jsonDatetoDate', function (value: string) {
  const res: Date = parseJSON(value);
  if (!isValid(res)) return undefined;
  return format(res, 'dd MMMM yyyy', { locale: fr });
});

export const jsonDatetoShortDate = Vue.filter('jsonDatetoShortDate', function (value: string) {
  const res: Date = parseJSON(value);
  if (!isValid(res)) return undefined;
  return format(res, 'dd/MM/yyyy', { locale: fr });
});
