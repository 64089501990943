import { Module } from "vuex";
import { storeActions } from ".";

export const globalStoreActions = {
  showError: "global/showError",
  showSuccess: "global/showSuccess",
  hideNotification: "global/hideNotification",
  isLoading: "global/isLoading",
  loadApp: "global/loadApp",
};

export const globalStoreGetters = {
  hasError: "global/hasError",
  hasSuccess: "global/hasSuccess",
};

const initialState: () => IGlobalStoreState = () => ({
  appLoaded: false,
  isLoading: false,
  notificationMessage: undefined,
  notificationType: undefined,
});

export default {
  namespaced: true,

  state: initialState,

  mutations: {
    showNotification(state, { message, type }) {
      state.isLoading = false;
      state.notificationMessage = message;
      state.notificationType = type;
    },
    hideNotification(state) {
      state.notificationMessage = undefined;
      state.notificationType = undefined;
    },
    appLoaded(state) {
      state.appLoaded = true;
    },
    isLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
  },

  getters: {
    hasError(state) {
      return !!state.notificationMessage && state.notificationType === "error";
    },
    hasSuccess(state) {
      return !!state.notificationMessage && state.notificationType === "success";
    },
  },

  actions: {
    showError({ commit }, payload) {
      commit("showNotification", { ...payload, type: "error" });
    },
    showSuccess({ commit }, payload) {
      commit("showNotification", { ...payload, type: "success" });
    },
    hideNotification({ commit }) {
      commit("hideNotification");
    },
    isLoading({ commit }, payload) {
      commit("isLoading", payload);
    },
    async loadApp({ dispatch, commit }) {
      await dispatch(storeActions.user.init, undefined, { root: true });
      commit('appLoaded');
    },
  },
} as Module<IGlobalStoreState, unknown>;

interface IGlobalStoreState {
  notificationMessage: string;
  notificationType?: "error" | "success";
  appLoaded: boolean;
  isLoading: boolean;
}
