




































import Vue from "vue";
import * as _ from "lodash";

export default Vue.extend({
  name: "WispWidget",
});

export const closeWispWidgetIfOpen = _.debounce((menuIsOpening: boolean) => {
  const wispWidget: any = _.first(document.getElementsByClassName("wisp"));
  if (
    menuIsOpening &&
    wispWidget &&
    wispWidget.classList.contains("wisp--open")
  ) {
    setTimeout(() => {
      wispWidget.click();
    }, 0);
  }
}, 50);
