import IHomeLinkModel from "@/models/common/ihome-link-model"
import IHomeLinksCatalog from "@/core/common/ihome-links-catalog"

export default class BabySitterHomeLinksCatalog implements IHomeLinksCatalog {

  private babysitterAgencySections = (): IHomeLinkModel[] => [
    {
      title: "Catalogs.BabysitterAgencySections.profile.title",
      description: "Catalogs.BabysitterAgencySections.profile.description",
      imageSource: require("@/assets/images/dashboard/user.svg"),
      navigateTo: '/intervenant/profil',
      needContract: false
    },
    {
      title: "Catalogs.BabysitterAgencySections.jobs.title",
      description: "Catalogs.BabysitterAgencySections.jobs.description",
      imageSource: require("@/assets/images/dashboard/profiles.svg"),
      navigateTo: '/intervenant/searchjobs',
      needContract: false,
    },
    {
      title: "Catalogs.BabysitterAgencySections.contract.title",
      description: "Catalogs.BabysitterAgencySections.contract.description",
      imageSource: require("@/assets/images/dashboard/contract.svg"),
      navigateTo: '/intervenant/contrats',
      needContract: true
    },
    {
      title: "Catalogs.BabysitterAgencySections.payslip.title",
      description: "Catalogs.BabysitterAgencySections.payslip.description",
      imageSource: require("@/assets/images/dashboard/invoice.svg"),
      navigateTo: '/intervenant/fiches-de-paie',
      needContract: true
    },
    {
      title: "Catalogs.BabysitterAgencySections.prestation.title",
      description: "Catalogs.BabysitterAgencySections.prestation.description",
      imageSource: require("@/assets/images/dashboard/business-time.svg"),
      navigateTo: '/intervenant/interventions',
      needContract: true
    },
    {
      title: "Catalogs.BabysitterAgencySections.toread.title",
      description: "Catalogs.BabysitterAgencySections.toread.description",
      imageSource: require("@/assets/images/dashboard/tax.svg"),
      navigateTo: '/intervenant/a-consulter',
      needContract: true
    },
    {
      title: "Catalogs.BabysitterAgencySections.contacts.title",
      description: "Catalogs.BabysitterAgencySections.contacts.description",
      imageSource: require("@/assets/images/dashboard/contacts.svg"),
      navigateTo: '/intervenant/contacts',
      needContract: true
    },
    {
      title: "Catalogs.BabysitterAgencySections.sponsorship.title",
      description: "Catalogs.BabysitterAgencySections.sponsorship.description",
      imageSource: require("@/assets/images/dashboard/hands.svg"),
      navigateTo: "/intervenant/parrainage",
      needContract: false,
      displayInHeader: true,
    }
  ];

  public getLinks(hasContract: boolean): IHomeLinkModel[] {
    if (hasContract) {
      return this.babysitterAgencySections();
    } else {
      return this.babysitterAgencySections().filter(_ => _.needContract === false);
    }

  }
}
