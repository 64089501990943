import AuthService from "./core/auth/auth.service";
import HomeService from "./core/home/home-service";
import BabysitterProfileService from "./core/babysitter/profile/babysitter-profile.service";
import UserSettingsService from "./core/settings/user-settings.service";
import LocalStorageService from "./core/local-storage/local-storage.service";
import SponsorshipService from "./core/sponsorship/sponsorship.service";
import AuthRepository from "./infrastructure/auth/auth.repository";
import BabysitterProfileRepository from "./infrastructure/babysitter/profile/babysitter-profile.repository";
import SponsorshipRepository from "./infrastructure/sponsorship/sponsorship.repository";
import UserSettingsRepository from "./infrastructure/settings/user-settings.repository";
import CustomerHomeLinksCatalog from "./infrastructure/customer/home/customer-home-links-catalog";
import { CustomerService } from "./core/customer/customer.service";
import { CustomerRepository } from "./infrastructure/customer/customer.repository";
import { EmployeeContractService } from "./core/employees/contracts/employee-contract.service";
import { EmployeeContractRepository } from "./infrastructure/employees/contracts/employee-contract.repository";
import { CustomerContractService } from "./core/customer/contracts/customer-contract.service";
import { CustomerContractRepository } from "./infrastructure/customer/contracts/customer-contract.repository";
import BabySitterHomeLinksCatalog from "./infrastructure/babysitter/home/babysitter-home-links-catalog";
import BabysitterRenewalService from "./core/babysitter/renewal/babysitter-renewal.service";
import BabysitterRenewalRepository from "./infrastructure/babysitter/renewal/babysitter-renewal.repository";
import ContactsService from "./core/employees/contacts/contacts-service";
import EmployeeContactsCatalog from "./infrastructure/employees/contacts/employee-contacts-catalog";
import EmployeeContactsRepository from "./infrastructure/employees/contacts/employee-contacts.repository";
import CustomerRenewalRepository from "./infrastructure/customer/renewal/customer-renewal.repository";
import CustomerRenewalService from "./core/customer/renewal/customer-renewal-service";

export default {
  services: {
    auth: new AuthService(new AuthRepository()),
    home: new HomeService(new BabySitterHomeLinksCatalog(), new CustomerHomeLinksCatalog()),
    babysitter: {
      profile: new BabysitterProfileService(new BabysitterProfileRepository()),
      renewal: new BabysitterRenewalService(new BabysitterRenewalRepository()),
    },
    customer: {
      default: new CustomerService(new CustomerRepository()),
      contract: new CustomerContractService(new CustomerContractRepository()),
      renewal: new CustomerRenewalService(new CustomerRenewalRepository()),
    },
    localStorage: new LocalStorageService(),
    sponsorship: new SponsorshipService(new SponsorshipRepository()),
    settings: new UserSettingsService(new UserSettingsRepository()),
    employee: {
      contract: new EmployeeContractService(new EmployeeContractRepository()),
      contacts: new ContactsService(new EmployeeContactsCatalog(), new EmployeeContactsRepository()),
    },
  },
};
