export enum BabySitterExperienceCategorieEnum {
  AtFamilyHome = 0,
  AtPrivateHome = 1,
  DayCareCenter = 2,
  EntertainmentCenter = 3,
  SummerCamp = 4,
  Event = 5,
  Agency = 6,
  School = 7
}

export enum ChildrenAgeCategoryEnum {
  UpToSixMonth = 0,
  UpToOneYear = 1,
  UpToThreeYears = 3,
  UpToSixYears = 6,
  MoreThanSixYears = 7
}

export enum BabySitterExperienceDurationEnum {
  UpToThreeMonths = 3,
  UpToSixMonths = 6,
  UpToOneYear = 12,
  MoreThanOneYear = 13
}

export enum BabySitterExperienceGuardTypeEnum {
  BeforeOrAfterSchool = 0,
  Day = 1,
  Evening = 2,
  WeekEnd = 3,
}

export enum ChildrenNumCategoryEnum {
  OneChildren = 1,
  TwoChildren = 2,
  ThreeChildren = 3,
  FourChildrenAndMore = 4
}
