import IUserOptInSettings from "@/models/settings/iuser-optin-settings";
import IUserSettingsRepository from "./iuser-settings.repository";

export default class UserSettingsService {
  private _userSettingsRepository: IUserSettingsRepository;

  public constructor(babySitterSettingsRepository: IUserSettingsRepository) {
    this._userSettingsRepository = babySitterSettingsRepository;
  }

  public getOptInSettings(): Promise<IUserOptInSettings> {
    return this._userSettingsRepository.getOptInSettings();
  }

  public updateOptInSettings(model: IUserOptInSettings): Promise<void> {
    return this._userSettingsRepository.updateOptInSettings(model);
  }
}
