























































































import Vue from "vue";
import EditableContainer from "@/app/components/common/EditableContainer.vue";

export default Vue.extend({
  name: "CareServices",
  props: {
    houseworkService: Boolean,
    hasHomeworkHelpOption: Boolean,
    hasIroningOption: Boolean,
  },
  data() {
    return {
      standardCare: true,
      model: {
        availableForHousework: this.houseworkService,
        hasHomeworkHelpOption: this.hasHomeworkHelpOption,
        hasIroningOption: this.hasIroningOption,
      },
    };
  },
  components: {
    EditableContainer,
  },
  methods: {
    revert() {
      this.model.availableForHousework = this.houseworkService;
      this.model.hasHomeworkHelpOption = this.hasHomeworkHelpOption;
      this.model.hasIroningOption = this.hasIroningOption;
    },
    saveCareServices() {
      this.$emit("save", this.model);
    },
  },
  watch: {
    "model.availableForHousework": function (newValue: boolean) {
      if (!newValue) {
        this.model.hasIroningOption = false;
      }
    },
  },
});
