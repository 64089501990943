import Vue from 'vue'
import VueI18n from 'vue-i18n'
import fr from '@/app/locales/fr';
import en from '@/app/locales/en';
import appFactories from '@/app.factories';
import appConfig from "@/app.config";

Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: appFactories.services.localStorage.language(),
  fallbackLocale: appConfig.i18nFallbackLocale || 'fr',
  messages: {
    fr,
    en,
  },
});

export function translate(key: string) {
  return i18n.t(key);
}
