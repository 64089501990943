import appConfig from "@/app.config";
import IUserSettingsRepository from "@/core/settings/iuser-settings.repository";
import HttpRepository from "@/infrastructure/http.repository";
import IUserOptInSettings from "@/models/settings/iuser-optin-settings";

export default class UserSettingsRepository extends HttpRepository implements IUserSettingsRepository {
  private webApiUrls = {
    getOptInSettings: `${appConfig.webApiBaseUrl}/OptIn/GetMyOptIn`,
    updateOptInSettings: `${appConfig.webApiBaseUrl}/OptIn/UpdateMyOptIn`,
  };

  public getOptInSettings(): Promise<IUserOptInSettings> {
    return this.httpGet(this.webApiUrls.getOptInSettings);
  }

  public updateOptInSettings(model: IUserOptInSettings): Promise<void> {
    return this.httpPost(this.webApiUrls.updateOptInSettings, model);
  }
}
