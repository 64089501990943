






























import Vue from "vue";
import ContactsCardInfo from "@/app/babysitter/components/contacts/ContactsCardInfo.vue";
import ContactsCardCoordinate from "@/app/babysitter/components/contacts/ContactsCardCoordinate.vue";

export default Vue.extend({
  name: "ContactsCard",
  props: {
    id: Number,
    title: String,
    desc: String,
    info1: String,
    info2: String,
    info3: String,
    isStandard: Boolean,
  },
  components: {
    ContactsCardInfo,
    ContactsCardCoordinate,
  },

  methods: {
  }

})
