













































































































import NavigablePage from "@/app/components/common/NavigablePage.vue";
import Vue from "vue";
import { storeGetters }  from "@/app/store";
import { mapState } from "vuex";
import factories from "@/app.factories";
import CompleteBackToSchoolInformationCommand  from "@/models/customer/renewal/customer-renewal-model";
import {CustomerEmployeeRenewalCompleteViewModel}  from "@/models/customer/renewal/customer-employee-renewal-complete-list-view-model";

export default Vue.extend({
    components: { NavigablePage },
    name: "CustomerRenewalPage",

    data() {
        return {
                    needRenewContract: null as boolean|null,
                    needContinueWithBS: null as boolean|null,
                    needBSForSummer: null as boolean|null,
                    commentNeedNewBS: '' as string,
                    needComment : '' as string,
                    currentYear: new Date().getFullYear(),
                    nextYear: new Date().getFullYear() + 1,
                    hasSent:false as boolean,
                    hasError:false as boolean,
                    isDisplayedInformationBanner: this.$store.getters[storeGetters.user.isDisplayedInformationBanner],
                    linkedEmployees: [] as CustomerEmployeeRenewalCompleteViewModel[],
                    isButtonDisable: false
        };
    },

    methods: {
        async sendBackToSchoolForm(){
            this.isButtonDisable = true;
            try {
                const modelForm = new CompleteBackToSchoolInformationCommand(this.needRenewContract, this.needContinueWithBS, this.needBSForSummer, this.commentNeedNewBS, this.needComment, this.parentId, this.linkedEmployees);
                await factories.services.customer.renewal.sendBackToSchoolForm(modelForm);
                this.hasError = false;
                this.hasSent = true;
            }
            catch(e){
                this.hasError = true;
            }
            this.isButtonDisable = false;
        }
    },

    computed: {
        ...mapState({
            parentId: (state: any) => state.user.id,
        })
    },

    async mounted(){
        try{
            this.linkedEmployees = await factories.services.customer.renewal.getCustomerRenewalCompleteLinkedEmployees();
        }
        catch(e){
            this.linkedEmployees = [];
            this.hasError = true;
        }
    }
});
