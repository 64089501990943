







































































































































import Vue from "vue";
import { mapState } from "vuex";
import { differenceInYears, parseJSON } from "date-fns";
import AdvertPublication from "@/app/babysitter/components/profile/AdvertPublication.vue";
import * as _ from "lodash";
import { FormValidators } from "@/app/helpers/forms/form.validators";
import EditableContainer from "@/app/components/common/EditableContainer.vue";
import AvatarEditor from "./AvatarEditor.vue";

export default Vue.extend({
  name: "Resume",
  components: {
    AvatarEditor,
    AdvertPublication,
    EditableContainer,
  },
  data() {
    return {
      title: this.advertTitle,
      description: this.advertDescription,
      validationRules: {
        title: [FormValidators.required],
        description: [FormValidators.required],
      },
    };
  },
  props: {
    advertTitle: String,
    AdvertPublication: String,
    advertDescription: String,
    advertIsPublished: Boolean,
    advertIsEnabled: Boolean,
    birthDate: String,
    phoneNumber: String,
    address: String,
    addressComplement: String,
    zipCode: String,
    city: String,
  },
  computed: {
    ...mapState({
      displayName: (state: any) => state.user.displayName,
      email: (state: any) => state.user.email,
    }),
    age(): number {
      const date = parseJSON(this.birthDate);
      const age = differenceInYears(new Date(), date);
      return age;
    },
    descWordCount(): number {
      return this.computeWordsCount(this.description);
    },
    titleWordCount(): number {
      return this.computeWordsCount(this.title);
    },
    canSave(): boolean {
      return (
        this.descWordCount >= 30 &&
        this.titleWordCount >= 3 &&
        this.titleWordCount <= 12
      );
    },
    hasAddress(): boolean {
      return (
        !!this.address ||
        !!this.addressComplement ||
        !!this.zipCode ||
        !!this.city
      );
    },
  },
  methods: {
    cancelAdvertEdition(): void {
      this.title = this.advertTitle;
      this.description = this.advertDescription;
    },
    saveAdvert(): void {
      this.$emit("save", {
        advertTitle: this.title,
        advertDescription: this.description,
      });
    },
    computeWordsCount(input: string) {
      return _.words(input).length;
    },
  },
});
