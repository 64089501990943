import IBabysitterProfileRepository from "@/core/babysitter/profile/ibabysitter-profile.repository";
import appConfig from "@/app.config";
import HttpRepository from "@/infrastructure/http.repository";
import IBabySitterProfileModel from "@/models/babysitter/profile/ibabysitter-profile-model";
import IBabySitterExperienceModel from "@/models/babysitter/profile/ibabysitter-experience-model";
import IBabysitterAvailabilityModel from "@/models/babysitter/profile/ibabysitter-availability-model";
import { CriteriaEducationLevelEnum, CriteriaEducationThemeEnum, CriteriaDiplomasEnum, CriteriaSpokenLanguageEnum } from "@/models/babysitter/profile/criteria.enum";
import { BabySitterProfessionalSituationEnum } from "@/models/babysitter/profile/babysitter-professional-situation.enum";
import { IHierarchyTextValueCodeItem } from "@/models/common/IhierarchyTextValueCodeItem-model";
import { BabySitterSearchFrequencyEnum } from "@/models/babysitter/profile/babysitter-search-frequency.enum";

export default class BabysitterProfileRepository
  extends HttpRepository
  implements IBabysitterProfileRepository {

  private webApiUrls = {
    getProfile: `${appConfig.webApiBaseUrl}/BabysitterProfile/GetMyBabysitterProfile`,
    deleteAvatar: `${appConfig.webApiBaseUrl}/BabysitterProfile/DeleteBabySitterAvatar`,
    updateAvatar: `${appConfig.webApiBaseUrl}/BabysitterProfile/UpdateBabySitterAvatar`,
    getProfileCompletionRate: `${appConfig.webApiBaseUrl}/BabysitterProfile/GetMyBabySitterProfileCompletionRate`,
    saveExperiences: `${appConfig.webApiBaseUrl}/BabysitterProfile/SaveExperiences`,
    saveAvailabilities: `${appConfig.webApiBaseUrl}/BabysitterProfile/UpdateMyAvailabilities`,
    saveGuardsType: `${appConfig.webApiBaseUrl}/BabysitterProfile/UpdateMyGuardTypes`,
    updateAdvertPublication: `${appConfig.webApiBaseUrl}/BabysitterProfile/UpdateAdvertPublication`,
    saveEducation: `${appConfig.webApiBaseUrl}/BabysitterProfile/UpdateMyEducation`,
    saveTransport: `${appConfig.webApiBaseUrl}/BabysitterProfile/UpdateMyTransportAbilities`,
    saveLanguages: `${appConfig.webApiBaseUrl}/BabysitterProfile/UpdateMyLanguages`,
    saveAdvert: `${appConfig.webApiBaseUrl}/BabysitterProfile/UpdateMyAdvert`,
    updateProfessionalSituation: `${appConfig.webApiBaseUrl}/BabysitterProfile/UpdateMyProfessionalSituation`,
    updateSearchFrequency: `${appConfig.webApiBaseUrl}/BabysitterProfile/UpdateMySearchFrequency`,
    getDiplomaList: `${appConfig.webApiBaseUrl}/BabysitterProfile/Diplomas`,
  };

  public async getProfile(): Promise<IBabySitterProfileModel> {
    return this.httpGet(this.webApiUrls.getProfile);
  }

  public async updateAvatar(file: File): Promise<string> {
    const formData = new FormData();
    formData.append('avatar', file, file.name);
    const response = await this.httpPost(this.webApiUrls.updateAvatar, formData);
    return response.object;
  }

  public deleteAvatar(): Promise<void> {
    return this.httpPost(this.webApiUrls.deleteAvatar);
  }

  public async saveExperiences(babySitterId: number, experiences: IBabySitterExperienceModel[]): Promise<any> {
    return this.httpPost(this.webApiUrls.saveExperiences,
      {
        "BabySitterID": babySitterId,
        "Experiences": experiences.map(experience => { return { ...experience, id: 0 } })
      });
  }

  public async saveAvailabilities(availabilities: IBabysitterAvailabilityModel[]): Promise<any> {
    return this.httpPost(this.webApiUrls.saveAvailabilities,
      {
        "Availabilities": availabilities
      });
  }

  public async saveGuardTypes(availableForHousework: boolean, hasHomeworkHelpOption: boolean, hasIroningOption: boolean): Promise<any> {
    return this.httpPost(this.webApiUrls.saveGuardsType,
      {
        "IsAvailableForHousework": availableForHousework,
        "HasHomeworkHelpOption": hasHomeworkHelpOption,
        "HasIroningOption": hasIroningOption,
      });
  }

  public updateAdvertPublication(publish: boolean): Promise<void> {
    return this.httpPost(this.webApiUrls.updateAdvertPublication,
      {
        "IsPublished": publish,
      });
  }

  public saveEducation(educationLevel: CriteriaEducationLevelEnum, educationTheme: CriteriaEducationThemeEnum, diplomas: CriteriaDiplomasEnum[]): Promise<any> {
    return this.httpPost(this.webApiUrls.saveEducation,
      {
        "EducationLevel": educationLevel || null,
        "EducationTheme": educationTheme,
        "SpecificDiplomas": diplomas
      });
  }

  public saveTransport(hasDrivingLicence: boolean, canConveyChildren: boolean): Promise<any> {
    return this.httpPost(this.webApiUrls.saveTransport,
      {
        "HasDrivingLicence": hasDrivingLicence,
        "CanConveyChildren": canConveyChildren
      });
  }

  public saveLanguages(spokenLanguages: CriteriaSpokenLanguageEnum[]): Promise<any> {
    return this.httpPost(this.webApiUrls.saveLanguages,
      {
        "SpokenLanguages": spokenLanguages
      });
  }

  public saveAdvert(title: string, description: string): Promise<any> {
    return this.httpPost(this.webApiUrls.saveAdvert, {
      "AdvertTitle": title,
      "AdvertDescription": description
    });
  }

  public getProfileCompletionRate(): Promise<any> {
    return this.httpGet(this.webApiUrls.getProfileCompletionRate);
  }

  public updateProfessionalSituation(professionalSituation: BabySitterProfessionalSituationEnum): Promise<any> {
    return this.httpPost(this.webApiUrls.updateProfessionalSituation, {
      "ProfessionalSituation": professionalSituation,
    });
  }

  public updateSearchFrequency(searchFrequency: BabySitterSearchFrequencyEnum): Promise<any> {
    return this.httpPost(this.webApiUrls.updateSearchFrequency, {
      "SearchFrequency": searchFrequency,
    });
  }

  public getDiplomaList(): Promise<IHierarchyTextValueCodeItem[]> {
    return this.httpGet(this.webApiUrls.getDiplomaList);
  }
}
