import Role from "@/models/auth/role.enum";
import store, { storeGetters } from "../../store";
import BabySitterProfilePage from "@/app/babysitter/pages/BabysitterProfilePage.vue";
import UserSettingsPage from "@/app/user/pages/UserSettingsPage.vue";
import UserSponsorshipPage from "@/app/user/pages/UserSponsorshipPage.vue";
import EmployeeContractsPage from "@/app/babysitter/pages/EmployeeContractsPage.vue";
import EmployeeRenewalPage from "@/app/babysitter/pages/EmployeeRenewalPage.vue";
import EmployeeContactsPage from "@/app/babysitter/pages/EmployeeContactsPage.vue"
import { RouteConfig } from "vue-router";
import appConfig from "@/app.config";

const oldBbaySitterPrivateSpaceUrls = {
  prestations: `${appConfig.oldPrivateSpaceBaseUrl}/espace-intervenant/interventions`,
  toread: `${appConfig.oldPrivateSpaceBaseUrl}/espace-intervenant/a-consulter`,
  payslips: `${appConfig.oldPrivateSpaceBaseUrl}/espace-intervenant/fiches-de-paie`
};

export const babySitterRoutes: Array<RouteConfig> = [
  {
    path: "/profil",
    component: BabySitterProfilePage,
    meta: {
      auth: true,
      roles: [Role.Babysitter],
    },
  },
  {
    path: "/parametres",
    component: UserSettingsPage,
    meta: {
      auth: true,
      roles: [Role.Babysitter]
    }
  },
  {
    path: "/contrats",
    component: EmployeeContractsPage,
    meta: {
      auth: true,
      roles: [Role.Babysitter]
    },
  },
  {
    path: "/rentree-scolaire",
    component: EmployeeRenewalPage,
    meta: {
      auth: true,
      roles: [Role.Babysitter]
    },
  },
  {
    path: "/contacts",
    component: EmployeeContactsPage,
    meta: {
      auth: true,
      roles: [Role.Babysitter],
    },
  },
  {
    path: "/interventions",
    meta: {
      auth: true,
      roles: [Role.Babysitter]
    },
    beforeEnter() {
      window.location.href = oldBbaySitterPrivateSpaceUrls.prestations
    }
  },
  {
    path: "/a-consulter",
    meta: {
      auth: true,
      roles: [Role.Babysitter]
    },
    beforeEnter() {
      window.location.href = oldBbaySitterPrivateSpaceUrls.toread
    }
  },
  {
    path: "/fiches-de-paie",
    meta: {
      auth: true,
      roles: [Role.Babysitter]
    },
    beforeEnter() {
      window.location.href = oldBbaySitterPrivateSpaceUrls.payslips
    }
  },
  {
    path: "/searchjobs",
    meta: {
      auth: true,
      roles: [Role.Babysitter, Role.Parent]
    },
    beforeEnter() {
      let searchUrl = store.getters[storeGetters.user.frontSearchUrl];
      if (store.getters[storeGetters.user.role] === Role.Parent) {
        searchUrl = '/accueil';
      }
      window.location.href = searchUrl;
    }
  },
  {
    path: "/parrainage",
    component: UserSponsorshipPage,
    meta: {
      auth: true,
      roles: [Role.Babysitter]
    }
  }
];
