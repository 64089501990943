import Vue from "vue";
import VueRouter, { Route, RouteConfig } from "vue-router";
import LoginPage from "@/app/auth/pages/LoginPage.vue";
import ResetPasswordPage from "@/app/auth/pages/ResetPasswordPage.vue";
import HomePage from "@/app/home/pages/HomePage.vue";
import UnauthorizedPage from "@/app/errors/pages/UnauthorizedPage.vue";
import ErrorPage from "@/app/errors/pages/ErrorPage.vue";
import authGuard from "@/app/auth/guards/auth.guard";
import registerExpiredTokenInterceptor from "./app/auth/interceptors/expired-token.interceptor";
import NotFoundPage from "@/app/errors/pages/NotFoundPage.vue";
import Role from "@/models/auth/role.enum";
import store from "./app/store";
import appConfig from "@/app.config";
import { customerRoutes } from './app/customer/routes';
import { babySitterRoutes } from './app/babysitter/routes';
import rolesGuard from "./app/auth/guards/roles.guard";

Vue.use(VueRouter);

const withPrefix = (prefix, routes) =>
  routes.map((route) => {
    route.path = prefix + route.path;
    return route;
  });

const routes: Array<RouteConfig> = [
  {
    path: "/",
    redirect: (to: Route) => {
      if (to.query["redirectUrl"]) {
        const redirectUrl = new URL(to.query["redirectUrl"] as string);

        if (redirectUrl.origin === window.location.origin) {
          window.location.replace(to.query["redirectUrl"] as string);
        }
      }

      const state: any = store.state;
      return state.user.role === Role.Babysitter || state.user.role === Role.Parent
        ? '/accueil'
        : '/login';
    }
  },
  {
    path: "/accueil",
    component: HomePage,
    meta: {
      auth: true,
    }
  },
  {
    path: "/login",
    component: LoginPage,
  },
  {
    path: "/reinitialisation-mot-de-passe",
    component: ResetPasswordPage,
  },
  {
    path: "/acces-non-autorise",
    component: UnauthorizedPage,
  },
  {
    path: "/erreur",
    component: ErrorPage,
  },
  ...withPrefix("/intervenant", babySitterRoutes),
  ...withPrefix('/client', customerRoutes),
  {
    path: "*",
    component: NotFoundPage,
  },
];

const router = new VueRouter({
  mode: "history",
  base: appConfig.baseUrl,
  routes,
});

router.beforeEach(authGuard);
router.beforeEach(rolesGuard);
registerExpiredTokenInterceptor();

export default router;
