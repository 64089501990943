


































import Vue from "vue";
import Role from "@/models/auth/role.enum";
import { storeGetters }  from "@/app/store";
import NavigablePage from "@/app/components/common/NavigablePage.vue";
import UserSettingsPassword from "@/app/user/components/settings/UserSettingsPassword.vue";
import UserSettingsLanguageSelector from "@/app/user/components/settings/UserSettingsLanguageSelector.vue";
import UserSettingsOptIn from "@/app/user/components/settings/UserSettingsOptIn.vue";
import appFactories from "@/app.factories";
import UserSettingsDeleteAccount from "@/app/user/components/settings/UserSettingsDeleteAccount.vue";
import IUserOptInSettings from "@/models/settings/iuser-optin-settings";

export default Vue.extend({
  name: "UserSettingsPage",
  components: {
    NavigablePage,
    UserSettingsPassword,
    UserSettingsLanguageSelector,
    UserSettingsOptIn,
    UserSettingsDeleteAccount,
  },

  data() {
    return {
      optIn: undefined,
      isDisplayedInformationBanner: this.$store.getters[storeGetters.user.isDisplayedInformationBanner],
      isParent: this.$store.getters[storeGetters.user.role] == Role.Parent
    };
  },

  async mounted() {
    this.optIn = await this.loadOptInSettings();
  },

  methods: {
    loadOptInSettings() {
      return appFactories.services.settings.getOptInSettings();
    },

    saveOptInSettings(model: IUserOptInSettings) {
      appFactories.services.settings.updateOptInSettings(model);
    },
  },
});
