import { getNavigatorLanguage } from "../helpers/navigator.helpers";
import appConfig from "@/app.config";

enum LocalStorageKeys {
  isAuth = "yoopala/isAuth",
  language = "yoopala/language",
}

export default class LocalStorageService {
  private set<T>(key: LocalStorageKeys, value: T): void {
    localStorage.setItem(key, JSON.stringify(value));
  }

  private get<T>(key: LocalStorageKeys): T {
    return JSON.parse(localStorage.getItem(key));
  }

  public setIsAuth(value: boolean) {
    this.set(LocalStorageKeys.isAuth, value);
  }

  public isAuth() {
    return this.get<boolean>(LocalStorageKeys.isAuth);
  }

  public setLanguage(v: string) {
    this.set(LocalStorageKeys.language, v);
  }

  public language() {
    return this.get<string>(LocalStorageKeys.language) || getNavigatorLanguage().substring(0, 2) || appConfig.i18nLocale || 'fr';
  }
}
