














































import Vue, { PropType } from "vue";
import { CriteriaSpokenLanguageEnum } from "@/models/babysitter/profile/criteria.enum";
import * as _ from "lodash";
import { getEnumValues } from "@/core/helpers/enum.helper";
import EditableContainer from "@/app/components/common/EditableContainer.vue";
export default Vue.extend({
  name: "SpokenLanguage",
  props: {
    spokenLanguages: Array as PropType<CriteriaSpokenLanguageEnum[]>,
  },
  components: {
    EditableContainer,
  },
  data() {
    return {
      selectedLanguages: this.spokenLanguages,
    };
  },
  computed: {
    allLanguages(): CriteriaSpokenLanguageEnum[] {
      return getEnumValues(CriteriaSpokenLanguageEnum);
    },

    hasLanguages(): boolean {
      return !_.isEmpty(this.selectedLanguages);
    },
  },
  methods: {
    revert() {
      this.selectedLanguages = this.spokenLanguages;
    },
    saveLanguages() {
      this.$emit("save", this.selectedLanguages);
    },
  },
});
