























import Vue from "vue";
export default Vue.extend({
  name: "ProfileProgressionPanel",

  props: {
    progression: {
      type: Number,
      default: 0,
    },
  },
});
