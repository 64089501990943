


























import Vue from "vue";
import ConfirmModal from "@/app/components/common/ConfirmModal.vue";
import appFactories from "@/app.factories";
import { storeActions } from "@/app/store";
export default Vue.extend({
  name: "UserSettingsDeleteAccount",

  components: {
    ConfirmModal,
  },

  data() {
    return {
      showConfirmDeleteAccount: false,
    };
  },

  methods: {
    async deleteAccount() {
      this.showConfirmDeleteAccount = false;
      await this.$store.dispatch(storeActions.global.isLoading, true);
      try {
        await appFactories.services.auth.deleteAccount();
        this.$store.dispatch(storeActions.global.showSuccess, {
          message: this.$i18n.t("UserSettingsDeleteAccount.actionConfirmation"),
        });
        this.$store.dispatch(storeActions.user.clear);
        this.$router.replace("/login");
      } finally {
        await this.$store.dispatch(storeActions.global.isLoading, false);
      }
    },
  },
});
