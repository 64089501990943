import Role from "@/models/auth/role.enum";
import IHomeLinkModel from "@/models/common/ihome-link-model";
import IHomeLinksCatalog from "../common/ihome-links-catalog";

export default class HomeService {
  private _babysitterCatalog: IHomeLinksCatalog;
  private _customerCatalog: IHomeLinksCatalog;

  public constructor(babysitterCatalog: IHomeLinksCatalog, customerCatalog: IHomeLinksCatalog) {
    this._babysitterCatalog = babysitterCatalog;
    this._customerCatalog = customerCatalog;
  }

  public getLinks(userRole: Role, hasContract: boolean): IHomeLinkModel[] {
    return userRole === Role.Babysitter
      ? this._babysitterCatalog.getLinks(hasContract)
      : this._customerCatalog.getLinks(hasContract);
  }
}
