export default {
  baseUrl: process.env.BASE_URL,
  webApiBaseUrl: process.env.VUE_APP_WEB_API_BASE_URL,
  authTokenKey: process.env.VUE_APP_AUTH_TOKEN_KEY,
  oldPrivateSpaceBaseUrl: process.env.VUE_APP_OLD_PRIVATE_SPACE_BASE_URL,
  wwwBaseUrl: process.env.VUE_APP_WWW_BASE_URL,
  photoGuideUrl: process.env.VUE_APP_PHOTO_GUIDE_URL,
  visibiliteCoordonneesGuideUrl: process.env.VUE_APP_VISIBILITE_COORDONNEES_GUIDE_URL,
  i18nFallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE,
  nodeEnv: process.env.NODE_ENV,
  i18nLocale: process.env.VUE_APP_I18N_LOCALE,
};
