import store from "@/app/store";
import { NavigationGuardNext, Route } from "vue-router";

async function rolesGuard(
  to: Route,
  from: Route,
  next: NavigationGuardNext<Vue>
): Promise<void> {
  const state: any = store.state;

  if (to.matched.some((record) => record.meta.roles)) {
    const userHasRoleForRoute = to.matched.reduce(
      (prev, curr) =>
        prev && curr.meta.roles.indexOf(state.user.role) > -1,
      true
    );
    if (!userHasRoleForRoute) {
      next({
        path: "/acces-non-autorise",
      });
    } else {
      next();
    }
  } else {
    next();
  }
}

export default rolesGuard;
