import Role from "@/models/auth/role.enum";
import ISponsorshipModel from "@/models/sponsorship/isponsorship-model";
import ISponsorshipRepository from "./isponsorship.repository";

export default class SponsorshipService {

  private _sponsorshipRepository: ISponsorshipRepository;

  public constructor(sponsershipRepository: ISponsorshipRepository) {
    this._sponsorshipRepository = sponsershipRepository;
  }

  public getMySponsorships(): Promise<ISponsorshipModel> {
    return this._sponsorshipRepository.getMySponsorships();
  }

  public sendInvite(role: Role, firstName: string, lastName: string, email: string): Promise<any> {
    return this._sponsorshipRepository.sendInvite(role, firstName, lastName, email);
  }
}
