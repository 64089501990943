















































import Vue from "vue";
export default Vue.extend({
  name: "NavigablePage",

  props: {
    title: String,
    isDisplayedInformationBanner: Boolean,
    isParent: Boolean
  },
});
