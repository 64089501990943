import { IEmployeeContractRepository } from "./iemployee-contract.repository";

export class EmployeeContractService {
  private _repository: IEmployeeContractRepository;

  public constructor(repository: IEmployeeContractRepository) {
    this._repository = repository;
  }

  public getMyContracts(): Promise<any> {
    return this._repository.getMyContracts();
  }
}
