






































































































































import Vue from "vue";
import { getEnumValues } from "@/core/helpers/enum.helper";
import * as _ from "lodash";
import {
  CriteriaEducationLevelEnum,
  CriteriaEducationThemeEnum,
} from "@/models/babysitter/profile/criteria.enum";
import EditableContainer from "@/app/components/common/EditableContainer.vue";
import factories from "@/app.factories";

export default Vue.extend({
  name: "EducationSection",

  components: {
    EditableContainer,
  },

  props: {
    educationLevel: Number,
    educationTheme: Number,
    specificDiplomas: Array,
  },

  data() {
    return {
      criteriaEducationLevelEnumValues: getEnumValues(
        CriteriaEducationLevelEnum
      ),
      criteriaEducationThemeEnumValues: getEnumValues(
        CriteriaEducationThemeEnum
      ),
      diplomaList: [],
      diplomaByValue: {},
      educationLevelModel: this.educationLevel,
      educationThemeModel: this.educationTheme,
      specificDiplomasModel: this.specificDiplomas,
    };
  },

  computed: {
    isEmpty(): boolean {
      return (
        this.educationLevelModelIsEmpty &&
        this.educationThemeModelIsEmpty &&
        this.specificDiplomasModelIsEmpty
      );
    },

    educationLevelModelIsEmpty(): boolean {
      return !this.educationLevelModel || this.educationLevelModel === 0;
    },

    educationThemeModelIsEmpty(): boolean {
      return !this.educationThemeModel || this.educationThemeModel === 0;
    },

    specificDiplomasModelIsEmpty(): boolean {
      return _.isEmpty(this.specificDiplomasModel);
    },
  },

  async mounted() {
    await this.loadDiplomas();
  },

  methods: {
    saveEducation() {
      this.$emit("save", {
        educationLevel: this.educationLevelModel,
        educationTheme: this.educationThemeModel,
        specificDiplomas: this.specificDiplomasModel,
      });
    },
    revert() {
      this.educationLevelModel = this.educationLevel;
      this.educationThemeModel = this.educationTheme;
      this.specificDiplomasModel = this.specificDiplomas;
    },
    async loadDiplomas() {
      const diplomaList =
        await factories.services.babysitter.profile.getDiplomaList();
      const diplomaByParent = _.groupBy(diplomaList, "parent");
      this.diplomaList = _.chain(diplomaList)
        .map((_) => ({
          ..._,
          header: !_.parent && diplomaByParent[_.value] ? _.text : undefined,
          first: !_.parent && !diplomaByParent[_.value],
        }))
        .orderBy(["first"], ["desc"])
        .value();
      this.diplomaByValue = _.keyBy(this.diplomaList, "value");
    },
  },
});
