export enum ParentContractStateEnum {
  Draft = 0,
  ScheduledForSending = 1,
  WaitingForParentSignature = 2,
  SignedByParent = 3,
  Error = 99,
  EarlyTermination = 4,
  Expired = 5,
  Refused = 6,
  Finished = 7,
  Canceled = 8,
  InProgress = 9
}