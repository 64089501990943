


















































































































































































































import Vue from "vue";
import * as _ from "lodash";
import { storeGetters }  from "@/app/store";
import NavigablePage from "@/app/components/common/NavigablePage.vue";
import appFactories from "@/app.factories";
import { BabySitterContractStateEnum } from "@/models/babysitter/BabySitterContractStateEnum.enum";
import { BabySitterContractRoleEnum } from "@/models/babysitter/BabySitterContractRoleEnum.enum";
export default Vue.extend({
  components: { NavigablePage },
  name: "EmployeeContractsPage",

  data() {
    return {
      isLoading: true,
      contracts: [],
      showAllContracts: false,
      isDisplayedInformationBanner: this.$store.getters[storeGetters.user.isDisplayedInformationBanner]
    };
  },

  async mounted() {
    await this.loadContracts();
  },

  methods: {
    async loadContracts() {
      this.isLoading = true;
      this.contracts =
        await appFactories.services.employee.contract.getMyContracts();
      this.isLoading = false;
    },

    getContractStateColor(contractState) {
      let color = "";

      switch (contractState) {
        case BabySitterContractStateEnum.InProgress:
          color = "green";
          break;
        case BabySitterContractStateEnum.PendingSignature:
          color = "blue";
          break;
        case BabySitterContractStateEnum.Signed:
          color = "teal";
          break;
        case BabySitterContractStateEnum.Finished:
          color = "primary";
          break;
      }

      return color;
    },

    getContractTypeColor(contractType) {
      let color = "";
      switch (contractType) {
        case BabySitterContractRoleEnum.Standard:
          color = "primary";
          break;
        case BabySitterContractRoleEnum.Referent:
          color = "teal white--text";
          break;
        case BabySitterContractRoleEnum.Tutor:
          color = "blue white--text";
          break;
        case BabySitterContractRoleEnum.Tutored:
          color = "purple white--text";
          break;
        case BabySitterContractRoleEnum.Housekeeper:
          color = "cyan white--text";
          break;
      }
      return color;
    },
  },

  computed: {
    displayedContracts(): any[] {
      if (!this.showAllContracts) {
        const contractBaseReferencesToDisplay: string[] = this.contracts
          .filter(
            (c) =>
              [
                BabySitterContractStateEnum.PendingSignature,
                BabySitterContractStateEnum.Signed,
                BabySitterContractStateEnum.InProgress,
              ].indexOf(c.state) > -1
          )
          .map((c) => c.baseReference);

        return _.chain(this.contracts)
          .filter(
            (c) => contractBaseReferencesToDisplay.indexOf(c.baseReference) > -1
          )
          .orderBy(["reference"], ["desc"])
          .value();
      }

      return _.chain(this.contracts).orderBy(["reference"], ["desc"]).value();
    },
  },
});
