import { ICustomerAdministrativeContacts } from "@/models/customer/icustomer-administrative-contacts.model";
import ICustomerRepository from "./icustomer.repository";

export class CustomerService {
  private _customerRepository: ICustomerRepository;

  public constructor(customerRepository: ICustomerRepository) {
    this._customerRepository = customerRepository;
  }

  public getAdministrativeContacts(): Promise<ICustomerAdministrativeContacts> {
    return this._customerRepository.getAdministrativeContacts();
  }
}
