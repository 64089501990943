












































































































import Vue from "vue";
import Role from "@/models/auth/role.enum";
import { storeGetters }  from "@/app/store";
import appFactories from "@/app.factories";
import NavigablePage from "@/app/components/common/NavigablePage.vue";
export default Vue.extend({
  components: { NavigablePage },
  name: "CustomerContactsPage",

  data() {
    return {
      administrativeContacts: undefined,
      isDisplayedInformationBanner: this.$store.getters[storeGetters.user.isDisplayedInformationBanner],
      isParent: this.$store.getters[storeGetters.user.role] == Role.Parent
    };
  },

  async mounted() {
    this.administrativeContacts =
      await appFactories.services.customer.default.getAdministrativeContacts();
  },
});
