



























































































import Vue from "vue";
import { mapState } from "vuex";
import appFactories from "@/app.factories";
import { storeActions } from "../../store";
import ResetPasswordModal from "../components/ResetPasswordModal.vue";
import { FormValidators } from "@/app/helpers/forms/form.validators";
import appConfig from "@/app.config";

export default Vue.extend({
  name: "LoginPage",

  components: {
    ResetPasswordModal,
  },

  data() {
    return {
      username: "",
      password: "",
      redirectUrl:
        this.$route.query["redirectUrl"] ||
        new URL(window.location.origin).href,
      loginUrl: appFactories.services.auth.getLoginUrl(),
      validationRules: {
        username: [FormValidators.required],
        password: [FormValidators.required],
      },
      showResetPasswordModal: false,
    };
  },

  computed: {
    ...mapState({
      isLoading: (state: any) => state.global.isLoading,
    }),
    signupLink() {
      return `${appConfig.wwwBaseUrl}/inscription/compte`;
    },
    headerIconUrl() {
      return appConfig.wwwBaseUrl;
    },
  },

  methods: {
    login(e) {
      const form = this.$refs.form as any;
      if (form.validate()) {
        this.$store.dispatch(storeActions.global.isLoading, true);
      } else {
        e.preventDefault();
      }
    },

    resetPassword(email: string) {
      this.showResetPasswordModal = false;
      appFactories.services.auth.requestResetPassword(email);
      this.$store.dispatch(storeActions.global.showSuccess, {
        message: this.$t("ResetPasswordModal.confirmation"),
      });
    },
  },
});
