















import Role from "@/models/auth/role.enum";
import SocialNetworks from "@/app/user/components/sponsorship/SocialNetworks.vue";
import NavigablePage from "@/app/components/common/NavigablePage.vue";
import { storeActions, storeGetters } from "@/app/store";
import Vue from "vue";
import factories from "@/app.factories";
import SponsorshipTracking from "@/app/user/components/sponsorship/SponsorshipTracking.vue";
import Sponsor from "@/app/user/components/sponsorship/Sponsor.vue";

export default Vue.extend({
  name: "UserSponsorshipPage",
  components: { NavigablePage, SocialNetworks, SponsorshipTracking, Sponsor },
  data() {
    return {
      sponsorshipsModel: undefined,
      isDisplayedInformationBanner: this.$store.getters[storeGetters.user.isDisplayedInformationBanner],
      isParent: this.$store.getters[storeGetters.user.role] == Role.Parent
    };
  },
  async mounted() {
    await this.loadSponsorships();
  },
  methods: {
    async loadSponsorships() {
      await this.$store.dispatch(storeActions.global.isLoading, true);
      try {
        this.sponsorshipsModel =
          await factories.services.sponsorship.getMySponsorships();
      } finally {
        await this.$store.dispatch(storeActions.global.isLoading, false);
      }
    },
  },
});
