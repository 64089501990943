













































import Vue from "vue";
export default Vue.extend({
  name: "EditableContainer",
  inheritAttrs: false,
  props: {
    title: String,
    editable: {
      type: Boolean,
      default: true,
    },
    mode: {
      type: String,
      default: "read",
    },
    enableSave: {
      type: Boolean,
      default: true,
    },
    titleClass: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      isEditing: this.mode === "write",
    };
  },
  methods: {
    toggleEdition() {
      const form = this.$refs.form as any;
      if (form.validate()) {
        if (this.isEditing) {
          this.$emit("save");
        } else {
          this.$emit("edit");
        }
        this.isEditing = !this.isEditing;
      }
    },
    cancel() {
      this.$emit("cancel");
      this.isEditing = !this.isEditing;
    },
  },
});
