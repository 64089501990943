
















































































































import factories from "@/app.factories";
import Vue from "vue";

export default Vue.extend({
  name: "ContactsCardCoordinate",
  props: {
    id: Number,
    info1: String,
    info2: String,
    info3: String,
    isStandard: Boolean,
  },

  data() {
    return {
      isLoading: true,
      referent: undefined,
      arh: undefined,
    }
  },

  async mounted() {
    await this.loadContacts();
  },

  methods: {
    async loadContacts() {
        this.isLoading = true;
        this.referent = await factories.services.employee.contacts.getContactsReferent();
        this.arh = await factories.services.employee.contacts.getContactARH();
        this.isLoading = false;
    },

    isActiveContract() : boolean {
        var res = false;
        const dateNow = new Date();
        this.referent.contactReferent.salarie.forEach(e => {
            var output = new Date(e.endDate);
            if( e.endDate == null || dateNow <= output){
                res = true;
            }
        })
        return res;
    }
  }

})
