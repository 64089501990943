import appConfig from "@/app.config";
import { ICustomerContractRepository } from "@/core/customer/contracts/icustomer-contract.repository";
import HttpRepository from "@/infrastructure/http.repository";

export class CustomerContractRepository
  extends HttpRepository
  implements ICustomerContractRepository {

  private webApiUrls = {
    getMyContracts: `${appConfig.webApiBaseUrl}/CustomerContract/GetMyContracts`,
    getHouseworkContracts: `${appConfig.webApiBaseUrl}/CustomerContract/GetHouseworkContracts`
  };

  public getMyContracts(): Promise<any> {
    return this.httpGet(this.webApiUrls.getMyContracts);
  }

  public getHouseworkContracts(): Promise<any> {
    return this.httpGet(this.webApiUrls.getHouseworkContracts)
  }
}
