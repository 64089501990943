

























import Vue, { PropType } from "vue";
import SponsorshipOfferModel from "@/models/sponsorship/isponsorship-offer-model";

export default Vue.extend({
  name: "SponsorshipTracking",
  props: {
    sponsoredParties: Array as PropType<SponsorshipOfferModel[]>,
  },
});
