


















import { storeActions } from "@/app/store";
import Vue from "vue";

export default Vue.extend({
  name: "AppSnackbar",

  props: {
    show: Boolean,
    message: String,
    type: String,
  },

  methods: {
    async hideSnackbar() {
      await this.$store.dispatch(storeActions.global.hideNotification);
    },
  },
});
