


























































import Vue from "vue";
import AppSnackbar from "@/app/components/layout/AppSnackbar.vue";
import { mapGetters, mapState } from "vuex";
import { storeActions, storeGetters } from "./store";
import AppHeaderBar from "@/app/components/layout/AppHeaderBar.vue";
import * as _ from "lodash";
import HeaderShapes from "./components/layout/HeaderShapes.vue";
import AppFooter from "./components/layout/AppFooter.vue";
import AppMobileMenu from "./components/layout/AppMobileMenu.vue";
import Role from "@/models/auth/role.enum";
import { headerMenuLinksCatalog } from "@/infrastructure/common/header-menu-links-catalog";
import appConfig from "@/app.config";
import factories from "@/app.factories";

export default Vue.extend({
  components: {
    AppSnackbar,
    AppHeaderBar,
    HeaderShapes,
    AppFooter,
    AppMobileMenu,
  },

  name: "App",

  data() {
    return {
      mobileMenuIsOpen: false,
      yoopalaLinks: headerMenuLinksCatalog,
      wwwBaseUrl: appConfig.wwwBaseUrl,
      userLinks: [],
    };
  },

  mounted() {
    this.loadUserLinks();
  },

  computed: {
    ...mapGetters({
      hasError: storeGetters.global.hasError,
      hasSuccess: storeGetters.global.hasSuccess,
      isAuthenticated: storeGetters.user.isAuthenticated,
    }),
    ...mapState({
      notificationMessage: (state: any) => state.global.notificationMessage,
      notificationType: (state: any) => state.global.notificationType,
      isLoading: (state: any) => state.global.isLoading,
      userRole: (state: any) => state.user.role,
      isEmployee: (state: any) => state.user.hasContracts,
      isDisplayedInformationBanner: (state: any) => state.user.isDisplayedInformationBanner,
    }),

    getRouterPathCssClass(): string {
      return `${_.kebabCase(this.$route.path, "/")}-page`;
    },

    settingsPageUrl(): string {
      return this.userRole === Role.Parent
        ? "/client/parametres"
        : "/intervenant/parametres";
    },
  },

  methods: {
    loadUserLinks() {
      this.userLinks = factories.services.home
        .getLinks(this.userRole, this.isEmployee)
        .filter((_) => _.displayInHeader);
    },

    logout() {
      this.mobileMenuIsOpen = false;
      this.$store.dispatch(storeActions.user.logout);
      this.$router.replace("/login");
    },
  },
});
