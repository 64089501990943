
















import Vue from "vue";
export default Vue.extend({
  name: "ConfirmModal",

  props: {
    open: Boolean,
    title: {
      type: String,
      default: "ConfirmModal.title",
    },
    message: {
      type: String,
      default: "ConfirmModal.message",
    },
    confirmMessage: {
      type: String,
      default: "Confirmer",
    },
    cancelMessage: {
      type: String,
      default: "Annuler",
    },
  },

  methods: {
    cancel() {
      this.$emit("cancel");
    },
    confirm() {
      this.$emit("confirm");
    },
  },
});
