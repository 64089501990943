




















































import Vue from "vue";
export default Vue.extend({
  name: "UserSettingsOptIn",

  props: {
    accountActivityEmail: {
      type: Boolean,
      default: true,
    },
    yoopalaInformationEmail: Boolean,
    yoopalaInformationSms: Boolean,
    yoopalaInformationPush: Boolean,
    partnersInformationEmail: Boolean,
    partnersInformationSms: Boolean,
    newAdvertsEmail: Boolean,
    isInBlockList: Boolean
  },

  data() {
    return {
      model: {
        yoopalaInformationEmail: this.yoopalaInformationEmail,
        yoopalaInformationSms: this.yoopalaInformationSms,
        yoopalaInformationPush: this.yoopalaInformationPush,
        partnersInformationEmail: this.partnersInformationEmail,
        partnersInformationSms: this.partnersInformationSms,
        newAdvertsEmail: this.newAdvertsEmail,
        isInBlockList: this.isInBlockList
      },
    };
  },

  methods: {
    updateOptIn() {
      this.$emit("save", this.model);
    },
  },
});
