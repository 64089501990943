







































import EditableContainer from "@/app/components/common/EditableContainer.vue";
import { getEnumValues } from "@/core/helpers/enum.helper";
import { BabySitterProfessionalSituationEnum } from "@/models/babysitter/profile/babysitter-professional-situation.enum";
import Vue from "vue";
export default Vue.extend({
  components: { EditableContainer },
  name: "ProfessionalSituation",
  props: {
    professionalSituation: Number,
  },
  data() {
    return {
      enumValues: getEnumValues(BabySitterProfessionalSituationEnum).filter(
        (_) =>
          _ !== BabySitterProfessionalSituationEnum.Indefini &&
          _ !== BabySitterProfessionalSituationEnum.Autre
      ),
      model: {
        // We need to add +1 to value because the first value enum is 0 and the checkbox will treat it as false
        professionalSituation: (this.professionalSituation || 0) + 1,
      },
    };
  },
  methods: {
    revert() {
      this.model.professionalSituation = this.professionalSituation + 1;
    },
    saveCareServices() {
      this.$emit("save", {
        professionalSituation: this.model.professionalSituation - 1,
      });
    },
    onCheckboxChange() {
      if (
        !this.model.professionalSituation ||
        this.model.professionalSituation < 1
      ) {
        this.model.professionalSituation = 1;
      }
    },
  },
});
