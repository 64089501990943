import appConfig from "@/app.config";
import ICustomerRepository from "@/core/customer/icustomer.repository";
import { ICustomerAdministrativeContacts } from "@/models/customer/icustomer-administrative-contacts.model";
import HttpRepository from "../http.repository";

export class CustomerRepository
  extends HttpRepository
  implements ICustomerRepository {

  private webApiUrls = {
    getAdministrativeContacts: `${appConfig.webApiBaseUrl}/AdministrativeContacts/GetMyAdministrativeContacts`,
  };

  public getAdministrativeContacts(): Promise<ICustomerAdministrativeContacts> {
    return this.httpGet(this.webApiUrls.getAdministrativeContacts);
  }

}
