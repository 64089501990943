





















































import { UserAvatarStateEnum } from "@/models/user/user-avatar-state.enum";
import Vue from "vue";
import { mapGetters, mapState } from "vuex";
import { storeGetters } from "../../../store";
export default Vue.extend({
  name: "AvatarEditorImage",

  data() {
    return {
      avatarIsError: false,
    };
  },

  computed: {
    ...mapGetters({
      avatarUrl: storeGetters.user.avatarUrl,
      defaultAvatar: storeGetters.user.defaultAvatar,
    }),
    ...mapState({
      avatarState: (state: any) => state.user.avatarState,
    }),
    computedAvatarUrl(): string {
      return this.avatarIsError ? this.defaultAvatar : this.avatarUrl;
    },
    isPending(): boolean {
      return this.avatarState === UserAvatarStateEnum.PendingValidation;
    },
    isRejected(): boolean {
      return this.avatarState === UserAvatarStateEnum.Rejected;
    },
  },

  methods: {
    onAvatarError() {
      this.avatarIsError = true;
    },
  },
});
