import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import fr from 'vuetify/lib/locale/fr';
import en from 'vuetify/lib/locale/en';
import appFactories from '@/app.factories';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#ff9e1b',
        anchor: 'inherit'
      },
    },
  },
  lang: {
    locales: { fr, en },
    current: appFactories.services.localStorage.language(),
  },
});
