import appFactories from "@/app.factories";
import axios, { AxiosError } from "axios";
import router from "../../../router";
import store, { storeActions } from "../../store";

const registerExpiredTokenInterceptor = () => {
  axios.interceptors.response.use(
    (response) => response,
    async (error: AxiosError) => {

      if (error.response?.status !== 401) {
        await store.dispatch(storeActions.global.showError, { message: extractErrorMessage(error) || "Internal Server Error" });
        throw error;
      }

      if (appFactories.services.localStorage.isAuth()) {
        await store.dispatch(storeActions.global.showError, { message: 'Unauthorized' });
      }

      await store.dispatch(storeActions.user.clear);

      if (router.currentRoute.meta.auth) {
        await router.replace('/login');
      }
    }
  );
}

function extractErrorMessage(error) {
  return error?.response?.data?.message || error?.response?.statusText;
}

export default registerExpiredTokenInterceptor;
