import { TranslateResult } from "vue-i18n";
import { i18n } from "../plugins/i18n";

export const IMAGE_MAX_SIZE = 10000000; // 2Mo
export const IMAGE_ALLOWED_TYPES = ["image/png", "image/jpeg", "image/gif"];

export const FormValidators = {
  required: (v: string): boolean | string | TranslateResult => !!v || i18n.t("FormValidators.required"),
  imageSize: (v: File): boolean | string | TranslateResult => (v && v.size && v.size < IMAGE_MAX_SIZE) || i18n.t("FormValidators.imageSize"),
  imageType: (v: File): boolean | string | TranslateResult => (v && v.type && IMAGE_ALLOWED_TYPES.indexOf(v.type) >= 0) || i18n.t("FormValidators.imageType"),
  emailFormat: (v: string): boolean | string | TranslateResult => (/^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v)) || i18n.t("FormValidators.emailFormat"),
};
