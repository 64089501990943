import { RouteConfig } from "vue-router";
import CustomerContactsPage from "@/app/customer/pages/CustomerContactsPage.vue";
import Role from "@/models/auth/role.enum";
import store, { storeGetters } from "../../store";
import UserSponsorshipPage from "@/app/user/pages/UserSponsorshipPage.vue";
import UserSettingsPage from "@/app/user/pages/UserSettingsPage.vue";
import CustomerContractsPage from "@/app/customer/pages/CustomerContractsPage.vue";
import CustomerRenewalPage from "@/app/customer/pages/CustomerRenewalPage.vue";
import appConfig from "@/app.config";

const oldCustomerPrivateSpaceUrls: any = {
  contracts: `${appConfig.oldPrivateSpaceBaseUrl}/espace-client/contrats`,
  prestations: `${appConfig.oldPrivateSpaceBaseUrl}/espace-client/interventions`,
  invoices: `${appConfig.oldPrivateSpaceBaseUrl}/espace-client/factures`,
  payments: `${appConfig.oldPrivateSpaceBaseUrl}/espace-client/reglements`,
  certificates: `${appConfig.oldPrivateSpaceBaseUrl}/espace-client/attestations-fiscales`,
  renewal: `${appConfig.oldPrivateSpaceBaseUrl}/espace-client/rentree-scolaire`,
}

export const customerRoutes: Array<RouteConfig> = [
  {
    path: "/contrats",
    component: CustomerContractsPage,
    meta: {
      auth: true,
      roles: [Role.Parent]
    },
  },
  {
    path: "/interventions",
    meta: {
      auth: true,
      roles: [Role.Parent]
    },
    beforeEnter() {
      window.location.href = oldCustomerPrivateSpaceUrls.prestations;
    }
  },
  {
    path: "/factures",
    meta: {
      auth: true,
      roles: [Role.Parent]
    },
    beforeEnter() {
      window.location.href = oldCustomerPrivateSpaceUrls.invoices;
    }
  },
  {
    path: "/reglements",
    meta: {
      auth: true,
      roles: [Role.Parent]
    },
    beforeEnter() {
      window.location.href = oldCustomerPrivateSpaceUrls.payments;
    }
  },
  {
    path: "/attestations",
    meta: {
      auth: true,
      roles: [Role.Parent]
    },
    beforeEnter() {
      window.location.href = oldCustomerPrivateSpaceUrls.certificates;
    }
  },
  {
    path: "/rentree-scolaire",
    component: CustomerRenewalPage,
    meta: {
      auth: true,
      roles: [Role.Parent]
    }
  },
  {
    path: "/profiles",
    meta: {
      auth: true,
      roles: [Role.Parent, Role.Babysitter]
    },
    beforeEnter() {
      let searchUrl = store.getters[storeGetters.user.frontSearchUrl];
      if (store.getters[storeGetters.user.role] === Role.Babysitter) {
        searchUrl = '/accueil';
      }
      window.location.href = searchUrl;
    }
  },
  {
    path: "/parrainage",
    component: UserSponsorshipPage,
    meta: {
      auth: true,
      roles: [Role.Parent]
    }
  },
  {
    path: "/parametres",
    component: UserSettingsPage,
    meta: {
      auth: true,
      roles: [Role.Parent]
    }
  },
  {
    path: "/contacts",
    component: CustomerContactsPage,
    meta: {
      auth: true,
      roles: [Role.Parent]
    }
  }
]
