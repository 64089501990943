import Vue from "vue";
import App from "./app/App.vue";
import router from "./router";
import store, { storeActions } from "./app/store";
import vuetify from "./app/helpers/plugins/vuetify";
import { sync } from 'vuex-router-sync';
import { i18n } from "./app/helpers/plugins/i18n";
import { jsonDatetoDate, jsonDatetoShortDate } from "./app/helpers/filters/date.filters";
import { lowercase } from "./app/helpers/filters/string.filters";
import { documentDownloadUrl } from "./app/helpers/filters/document.filters";

sync(store, router);

Vue.config.productionTip = false;

import "@/assets/styles/global.css";
import { avatarFallback } from "./app/helpers/directives/avatar-fallback.directive";

store.dispatch(storeActions.global.loadApp).finally(() => {
  new Vue({
    router,
    store,
    vuetify,
    i18n,
    filters: {
      jsonDatetoDate,
      jsonDatetoShortDate,
      lowercase,
      documentDownloadUrl,
    },
    directives: {
      avatarFallback,
    },
    render: (h) => h(App)
  }).$mount("#app");
});
