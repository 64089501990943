





































































import IBabySitterExperienceModel from "@/models/babysitter/profile/ibabysitter-experience-model";
import Vue, { PropType } from "vue";
import * as _ from "lodash";
import { mapState } from "vuex";
import Experience from "./Experience.vue";

export default Vue.extend({
  name: "BabySitterExperiences",
  data() {
    return {
      addInProgress: false,
    };
  },
  props: {
    experiences: Array as PropType<IBabySitterExperienceModel[]>,
    canEditExperiences: Boolean,
  },
  components: {
    Experience,
  },
  methods: {
    toLocaleDate(date:Date): string
    {
      return new Date(date.getTime() - new Date().getTimezoneOffset() * 60 * 1000).toISOString();
    },
    addExperience() {
      this.addInProgress = true;
      this.experiences.push({
        id: 0,
        bbsID: this.babySitterId,
        typologieCategorie: null,
        typologieDuree: null,
        typologieCategorieAge: null,
        description: null,
        guardType: null,
        dateOfCreation: this.toLocaleDate(new Date()),
        dateOfModification: null
      });
    },
    checkConsistency(experienceId: number) {
      const experience: IBabySitterExperienceModel = this.experiences.find(
        (_) => _.id === experienceId
      );
      if (
        experience !== null &&
        experience.typologieDuree === null &&
        experience.typologieCategorie === null &&
        experience.typologieCategorieAge === null &&
        experience.guardType === null &&
        experience.description === null
      ) {
        const index: number = this.experiences.indexOf(experience);
        this.experiences.splice(index, 1);
      }
      this.addInProgress = false;
    },
    removeExperience(experienceId: number) {
      const experience: IBabySitterExperienceModel = this.experiences.find(
        (_) => _.id === experienceId
      );
      if (experience !== null) {
        const index: number = this.experiences.indexOf(experience);
        this.experiences.splice(index, 1);
      }
      this.$emit("save");
    },
    saveExperiences() {
      this.$emit("save");
      this.addInProgress = false;
    },
  },
  computed: {
    ...mapState({
      babySitterId: (state: any) => state.user.id,
    }),
    hasExperiences(): boolean {
      return !_.isEmpty(this.experiences);
    },
  },
});
