
























import { FormValidators } from "@/app/helpers/forms/form.validators";
import Vue from "vue";
export default Vue.extend({
  name: "ResetPasswordModal",

  props: {
    show: Boolean,
  },

  data() {
    return {
      email: "",
      emailRule: [FormValidators.required],
    };
  },

  methods: {
    cancel() {
      this.email = "";
      this.$emit("cancel");
    },

    confirm() {
      const form = this.$refs.form as any;
      if (form.validate()) {
        this.$emit("confirm", this.email);
        this.email = "";
      }
    },
  },
});
