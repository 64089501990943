








































import appConfig from "@/app.config";
import Vue from "vue";
export default Vue.extend({
  name: "AdvertPublication",

  props: {
    isPublished: Boolean,
    isEnabled: Boolean,
  },

  data() {
    return {
      model: this.isPublished,
      visibiliteCoordonneesGuideUrl: appConfig.visibiliteCoordonneesGuideUrl,
    };
  },
});
