import axios from "axios";
import * as _ from 'lodash';

export default class HttpRepository {
  protected async httpGet(url: string, params?: any): Promise<any> {
    try {
      const result = await axios.get(url, {
        params,
        withCredentials: true,
      });
      return result.data;
    } catch (error) {
      throw new Error(this.extractErrorMessage(error));
    }
  }

  protected async httpPost(url: string, data?: any): Promise<any> {
    try {
      const result = await axios.post(url, data, {
        withCredentials: true,
      });
      return result.data;
    } catch (error) {
      throw new Error(this.extractErrorMessage(error));
    }
  }

  private extractErrorMessage(error) {
    return _.get(error, 'response.data.message') || _.get(error, 'response.statusText');
  }
}
