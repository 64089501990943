import Vue from "vue";
import Vuex from "vuex";
import appConfig from "@/app.config";
import globalStore, {
  globalStoreActions,
  globalStoreGetters,
} from "./global-store.module";
import userStore, {
  userStoreActions, userStoreGetters
} from "./user-store.module"

Vue.use(Vuex);

const debug = appConfig.nodeEnv !== "production";

export default new Vuex.Store({
  strict: debug,
  modules: {
    global: globalStore,
    user: userStore
  },
});

export const storeActions = {
  global: globalStoreActions,
  user: userStoreActions
};

export const storeGetters = {
  global: globalStoreGetters,
  user: userStoreGetters,
};
